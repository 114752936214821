/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ActionItemDeletionRequest,
    ActionItemDeletionRequestFromJSON,
    ActionItemDeletionRequestToJSON,
    AddRecordRequest,
    AddRecordRequestFromJSON,
    AddRecordRequestToJSON,
    CancelRecordRequest,
    CancelRecordRequestFromJSON,
    CancelRecordRequestToJSON,
    ChangeStatusResponse,
    ChangeStatusResponseFromJSON,
    ChangeStatusResponseToJSON,
    CloseRecordRequestV2,
    CloseRecordRequestV2FromJSON,
    CloseRecordRequestV2ToJSON,
    CreateRecordCommandResponse,
    CreateRecordCommandResponseFromJSON,
    CreateRecordCommandResponseToJSON,
    CreateRecordRequest,
    CreateRecordRequestFromJSON,
    CreateRecordRequestToJSON,
    DeleteRecordsCommand,
    DeleteRecordsCommandFromJSON,
    DeleteRecordsCommandToJSON,
    DirectCancelRecordRequest,
    DirectCancelRecordRequestFromJSON,
    DirectCancelRecordRequestToJSON,
    DirectCloseRecordRequest,
    DirectCloseRecordRequestFromJSON,
    DirectCloseRecordRequestToJSON,
    DirectReopenRecordRequest,
    DirectReopenRecordRequestFromJSON,
    DirectReopenRecordRequestToJSON,
    EditRecordRequest,
    EditRecordRequestFromJSON,
    EditRecordRequestToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    PDFOptions,
    PDFOptionsFromJSON,
    PDFOptionsToJSON,
    PartialRecordDetailViewV2,
    PartialRecordDetailViewV2FromJSON,
    PartialRecordDetailViewV2ToJSON,
    ParticipantListResponse,
    ParticipantListResponseFromJSON,
    ParticipantListResponseToJSON,
    RecordCollaboratorsResponse,
    RecordCollaboratorsResponseFromJSON,
    RecordCollaboratorsResponseToJSON,
    RecordCollectionResponseV2,
    RecordCollectionResponseV2FromJSON,
    RecordCollectionResponseV2ToJSON,
    RecordDetailView,
    RecordDetailViewFromJSON,
    RecordDetailViewToJSON,
    RecordDetailViewV2,
    RecordDetailViewV2FromJSON,
    RecordDetailViewV2ToJSON,
    RecordDetailViewV3,
    RecordDetailViewV3FromJSON,
    RecordDetailViewV3ToJSON,
    RecordListResponse,
    RecordListResponseFromJSON,
    RecordListResponseToJSON,
    RecordStatusParameter,
    RecordStatusParameterFromJSON,
    RecordStatusParameterToJSON,
    RecordTitleChangeCommandRequest,
    RecordTitleChangeCommandRequestFromJSON,
    RecordTitleChangeCommandRequestToJSON,
    RecordType,
    RecordTypeFromJSON,
    RecordTypeToJSON,
    RemoveRecordRequest,
    RemoveRecordRequestFromJSON,
    RemoveRecordRequestToJSON,
    ReopenRecordRequest,
    ReopenRecordRequestFromJSON,
    ReopenRecordRequestToJSON,
    SeriesRecordsResponse,
    SeriesRecordsResponseFromJSON,
    SeriesRecordsResponseToJSON,
    Task,
    TaskFromJSON,
    TaskToJSON,
} from '../models';

export interface AddRecordV2Request {
    parentRecordId: string;
    addRecordRequest: AddRecordRequest;
}

export interface CancelRecordOperationRequest {
    recordId: string;
    cancelRecordRequest: CancelRecordRequest;
}

export interface CancelRecordDirectV2Request {
    recordId: string;
    directCancelRecordRequest: DirectCancelRecordRequest;
}

export interface CloseRecordRequest {
    pcsTenantid: string;
    pcsAppid: string;
    recordId: string;
}

export interface CloseRecordDirectV2Request {
    recordId: string;
    directCloseRecordRequest: DirectCloseRecordRequest;
}

export interface CloseRecordV2Request {
    recordId: string;
    closeRecordRequestV2: CloseRecordRequestV2;
}

export interface CreatePDFOfRecordRequest {
    recordId: string;
    pDFOptions: PDFOptions;
}

export interface CreateRecordOperationRequest {
    createRecordRequest: CreateRecordRequest;
}

export interface DeleteRecordsByIdsRequest {
    deleteRecordsCommand: DeleteRecordsCommand;
}

export interface EditRecordOperationRequest {
    recordId: string;
    editRecordRequest: EditRecordRequest;
}

export interface EditRecordTitleRequest {
    recordId: string;
    recordTitleChangeCommandRequest: RecordTitleChangeCommandRequest;
}

export interface GetRecordCollaboratorsRequest {
    recordId: string;
}

export interface GetRecordCollectionV2Request {
    parentRecordId: string;
    pageNumber: number;
    pageSize: number;
    workflowId?: string;
    sortDirection?: GetRecordCollectionV2SortDirectionEnum;
    sortColumn?: GetRecordCollectionV2SortColumnEnum;
    recordStatuses?: Array<RecordStatusParameter>;
}

export interface GetRecordDetailByRecordIdRequest {
    recordId: string;
}

export interface GetRecordDetailByRecordIdV2Request {
    recordId: string;
    pcsTenantid?: string;
    pcsUserid?: string;
}

export interface GetRecordDetailByRecordIdV3Request {
    recordId: string;
    pcsTenantid?: string;
    pcsUserid?: string;
}

export interface GetRecordParticipantsRequest {
    recordId: string;
}

export interface GetRecordSeriesRequest {
    recordId?: string;
    seriesId?: string;
}

export interface GetRecordsV3Request {
    pageNumber: number;
    pageSize: number;
    pattern?: string;
    recordStatuses?: Array<RecordStatusParameter>;
    fromDate?: Date;
    toDate?: Date;
    completionDateFrom?: Date;
    completionDateTo?: Date;
    dueDateFrom?: Date;
    dueDateTo?: Date;
    initiationDateFrom?: Date;
    initiationDateTo?: Date;
    lastModifiedDateFrom?: Date;
    lastModifiedDateTo?: Date;
    appIds?: Array<string>;
    recordTypes?: Array<RecordType>;
    noRecordTypes?: Array<RecordType>;
    userIds?: Array<string>;
    excludeRestrictedAccess?: boolean;
    dataTypes?: Array<GetRecordsV3DataTypesEnum>;
}

export interface GetTasksOnRecordRequest {
    recordId: string;
}

export interface PublishRecordViewedEventRequest {
    recordId: string;
}

export interface RemoveActionItemV2Request {
    actionItemId: string;
    actionItemDeletionRequest: ActionItemDeletionRequest;
}

export interface RemoveRecordOperationRequest {
    parentRecordId: string;
    removeRecordRequest: RemoveRecordRequest;
}

export interface ReopenRecordOperationRequest {
    recordId: string;
    reopenRecordRequest: ReopenRecordRequest;
}

export interface ReopenRecordDirectRequest {
    recordId: string;
    directReopenRecordRequest: DirectReopenRecordRequest;
}

/**
 * 
 */
export class RecordApi extends runtime.BaseAPI {

    /**
     * Add a record to a collection - license required is Full or Basic
     * Add a record to a collection
     */
    async addRecordV2Raw(requestParameters: AddRecordV2Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PartialRecordDetailViewV2>> {
        if (requestParameters.parentRecordId === null || requestParameters.parentRecordId === undefined) {
            throw new runtime.RequiredError('parentRecordId','Required parameter requestParameters.parentRecordId was null or undefined when calling addRecordV2.');
        }

        if (requestParameters.addRecordRequest === null || requestParameters.addRecordRequest === undefined) {
            throw new runtime.RequiredError('addRecordRequest','Required parameter requestParameters.addRecordRequest was null or undefined when calling addRecordV2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/record/v2/{parentRecordId}/collection`.replace(`{${"parentRecordId"}}`, encodeURIComponent(String(requestParameters.parentRecordId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddRecordRequestToJSON(requestParameters.addRecordRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PartialRecordDetailViewV2FromJSON(jsonValue));
    }

    /**
     * Add a record to a collection - license required is Full or Basic
     * Add a record to a collection
     */
    async addRecordV2(requestParameters: AddRecordV2Request, initOverrides?: RequestInit): Promise<PartialRecordDetailViewV2> {
        const response = await this.addRecordV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a request to cancel a record. Canceling a record is intended for situations where the record was created in error. A request to cancel can be created by step participant after the first step.  Also first step participants may make a request to cancel if the first step was completed and is being revisited by a change request.         When the selected reason to cancel is OTHER, comments are required. License required is Full or Basic 
     * Request to cancel a record for a reason
     */
    async cancelRecordRaw(requestParameters: CancelRecordOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.recordId === null || requestParameters.recordId === undefined) {
            throw new runtime.RequiredError('recordId','Required parameter requestParameters.recordId was null or undefined when calling cancelRecord.');
        }

        if (requestParameters.cancelRecordRequest === null || requestParameters.cancelRecordRequest === undefined) {
            throw new runtime.RequiredError('cancelRecordRequest','Required parameter requestParameters.cancelRecordRequest was null or undefined when calling cancelRecord.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/record/v1/{recordId}/action/cancel`.replace(`{${"recordId"}}`, encodeURIComponent(String(requestParameters.recordId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CancelRecordRequestToJSON(requestParameters.cancelRecordRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Creates a request to cancel a record. Canceling a record is intended for situations where the record was created in error. A request to cancel can be created by step participant after the first step.  Also first step participants may make a request to cancel if the first step was completed and is being revisited by a change request.         When the selected reason to cancel is OTHER, comments are required. License required is Full or Basic 
     * Request to cancel a record for a reason
     */
    async cancelRecord(requestParameters: CancelRecordOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.cancelRecordRaw(requestParameters, initOverrides);
    }

    /**
     * Directly cancels a record for a reason without requiring an approval from the Event Owner to cancel the record. Canceling a record is intended for situations where the record was created in error. First step initiators can directly cancel if the first step has not been completed.  Event Owners or Sysadmins  can directly cancel a record on any step.         When the selected reason to cancel is OTHER, comments are required. License required is Full 
     * Directly cancels a record
     */
    async cancelRecordDirectV2Raw(requestParameters: CancelRecordDirectV2Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.recordId === null || requestParameters.recordId === undefined) {
            throw new runtime.RequiredError('recordId','Required parameter requestParameters.recordId was null or undefined when calling cancelRecordDirectV2.');
        }

        if (requestParameters.directCancelRecordRequest === null || requestParameters.directCancelRecordRequest === undefined) {
            throw new runtime.RequiredError('directCancelRecordRequest','Required parameter requestParameters.directCancelRecordRequest was null or undefined when calling cancelRecordDirectV2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/record/v2/{recordId}/action/directcancel`.replace(`{${"recordId"}}`, encodeURIComponent(String(requestParameters.recordId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DirectCancelRecordRequestToJSON(requestParameters.directCancelRecordRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Directly cancels a record for a reason without requiring an approval from the Event Owner to cancel the record. Canceling a record is intended for situations where the record was created in error. First step initiators can directly cancel if the first step has not been completed.  Event Owners or Sysadmins  can directly cancel a record on any step.         When the selected reason to cancel is OTHER, comments are required. License required is Full 
     * Directly cancels a record
     */
    async cancelRecordDirectV2(requestParameters: CancelRecordDirectV2Request, initOverrides?: RequestInit): Promise<void> {
        await this.cancelRecordDirectV2Raw(requestParameters, initOverrides);
    }

    /**
     * Close a record if all nodes are complete. Authentication is S2S only.
     * Close a record
     */
    async closeRecordRaw(requestParameters: CloseRecordRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.pcsTenantid === null || requestParameters.pcsTenantid === undefined) {
            throw new runtime.RequiredError('pcsTenantid','Required parameter requestParameters.pcsTenantid was null or undefined when calling closeRecord.');
        }

        if (requestParameters.pcsAppid === null || requestParameters.pcsAppid === undefined) {
            throw new runtime.RequiredError('pcsAppid','Required parameter requestParameters.pcsAppid was null or undefined when calling closeRecord.');
        }

        if (requestParameters.recordId === null || requestParameters.recordId === undefined) {
            throw new runtime.RequiredError('recordId','Required parameter requestParameters.recordId was null or undefined when calling closeRecord.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.pcsTenantid !== undefined && requestParameters.pcsTenantid !== null) {
            headerParameters['pcs-tenantid'] = String(requestParameters.pcsTenantid);
        }

        if (requestParameters.pcsAppid !== undefined && requestParameters.pcsAppid !== null) {
            headerParameters['pcs-appid'] = String(requestParameters.pcsAppid);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/record/v1/{recordId}/action/close`.replace(`{${"recordId"}}`, encodeURIComponent(String(requestParameters.recordId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Close a record if all nodes are complete. Authentication is S2S only.
     * Close a record
     */
    async closeRecord(requestParameters: CloseRecordRequest, initOverrides?: RequestInit): Promise<void> {
        await this.closeRecordRaw(requestParameters, initOverrides);
    }

    /**
     * Directly closes a record for a reason without requiring an approval from the Event Owner to close the record. Only Event Owners or Sysadmins can directly close a record. Closing a record is intended for situations where the event was valid but it wasn\'t necessary to complete it. When the selected reason to close is OTHER, comments are required. License required is Full 
     * Directly closes a record
     */
    async closeRecordDirectV2Raw(requestParameters: CloseRecordDirectV2Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.recordId === null || requestParameters.recordId === undefined) {
            throw new runtime.RequiredError('recordId','Required parameter requestParameters.recordId was null or undefined when calling closeRecordDirectV2.');
        }

        if (requestParameters.directCloseRecordRequest === null || requestParameters.directCloseRecordRequest === undefined) {
            throw new runtime.RequiredError('directCloseRecordRequest','Required parameter requestParameters.directCloseRecordRequest was null or undefined when calling closeRecordDirectV2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/record/v2/{recordId}/action/directclose`.replace(`{${"recordId"}}`, encodeURIComponent(String(requestParameters.recordId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DirectCloseRecordRequestToJSON(requestParameters.directCloseRecordRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Directly closes a record for a reason without requiring an approval from the Event Owner to close the record. Only Event Owners or Sysadmins can directly close a record. Closing a record is intended for situations where the event was valid but it wasn\'t necessary to complete it. When the selected reason to close is OTHER, comments are required. License required is Full 
     * Directly closes a record
     */
    async closeRecordDirectV2(requestParameters: CloseRecordDirectV2Request, initOverrides?: RequestInit): Promise<void> {
        await this.closeRecordDirectV2Raw(requestParameters, initOverrides);
    }

    /**
     * Creates a request to close a record.  This is different from v1. Closing a record is intended for situations where the event was valid but it wasn\'t necessary to complete it. Only first step Initiators and subsequent Step Assignees area allowed to create a request to Close.         When the selected reason is OTHER, comments are required. License required is Full or Basic 
     * Request to close a record for a reason
     */
    async closeRecordV2Raw(requestParameters: CloseRecordV2Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.recordId === null || requestParameters.recordId === undefined) {
            throw new runtime.RequiredError('recordId','Required parameter requestParameters.recordId was null or undefined when calling closeRecordV2.');
        }

        if (requestParameters.closeRecordRequestV2 === null || requestParameters.closeRecordRequestV2 === undefined) {
            throw new runtime.RequiredError('closeRecordRequestV2','Required parameter requestParameters.closeRecordRequestV2 was null or undefined when calling closeRecordV2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/record/v2/{recordId}/action/close`.replace(`{${"recordId"}}`, encodeURIComponent(String(requestParameters.recordId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CloseRecordRequestV2ToJSON(requestParameters.closeRecordRequestV2),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Creates a request to close a record.  This is different from v1. Closing a record is intended for situations where the event was valid but it wasn\'t necessary to complete it. Only first step Initiators and subsequent Step Assignees area allowed to create a request to Close.         When the selected reason is OTHER, comments are required. License required is Full or Basic 
     * Request to close a record for a reason
     */
    async closeRecordV2(requestParameters: CloseRecordV2Request, initOverrides?: RequestInit): Promise<void> {
        await this.closeRecordV2Raw(requestParameters, initOverrides);
    }

    /**
     * Creates a PDF from a given record
     * Creates a PDF from a given record - response is a PDF JSON blob
     */
    async createPDFOfRecordRaw(requestParameters: CreatePDFOfRecordRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.recordId === null || requestParameters.recordId === undefined) {
            throw new runtime.RequiredError('recordId','Required parameter requestParameters.recordId was null or undefined when calling createPDFOfRecord.');
        }

        if (requestParameters.pDFOptions === null || requestParameters.pDFOptions === undefined) {
            throw new runtime.RequiredError('pDFOptions','Required parameter requestParameters.pDFOptions was null or undefined when calling createPDFOfRecord.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/record/v1/{recordId}/action/generatePDF`.replace(`{${"recordId"}}`, encodeURIComponent(String(requestParameters.recordId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PDFOptionsToJSON(requestParameters.pDFOptions),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Creates a PDF from a given record
     * Creates a PDF from a given record - response is a PDF JSON blob
     */
    async createPDFOfRecord(requestParameters: CreatePDFOfRecordRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.createPDFOfRecordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a record from a workflow - license required is Full or Basic
     * Create a record from a workflow
     */
    async createRecordRaw(requestParameters: CreateRecordOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CreateRecordCommandResponse>> {
        if (requestParameters.createRecordRequest === null || requestParameters.createRecordRequest === undefined) {
            throw new runtime.RequiredError('createRecordRequest','Required parameter requestParameters.createRecordRequest was null or undefined when calling createRecord.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/record/v1/action/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateRecordRequestToJSON(requestParameters.createRecordRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateRecordCommandResponseFromJSON(jsonValue));
    }

    /**
     * Create a record from a workflow - license required is Full or Basic
     * Create a record from a workflow
     */
    async createRecord(requestParameters: CreateRecordOperationRequest, initOverrides?: RequestInit): Promise<CreateRecordCommandResponse> {
        const response = await this.createRecordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Supports deleting records for the record execution record aggregate by multiple ids.
     * Delete records by ids
     */
    async deleteRecordsByIdsRaw(requestParameters: DeleteRecordsByIdsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.deleteRecordsCommand === null || requestParameters.deleteRecordsCommand === undefined) {
            throw new runtime.RequiredError('deleteRecordsCommand','Required parameter requestParameters.deleteRecordsCommand was null or undefined when calling deleteRecordsByIds.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/record/v1`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteRecordsCommandToJSON(requestParameters.deleteRecordsCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Supports deleting records for the record execution record aggregate by multiple ids.
     * Delete records by ids
     */
    async deleteRecordsByIds(requestParameters: DeleteRecordsByIdsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteRecordsByIdsRaw(requestParameters, initOverrides);
    }

    /**
     * Edit a record - license required is Full or Basic
     * Edit a record
     */
    async editRecordRaw(requestParameters: EditRecordOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.recordId === null || requestParameters.recordId === undefined) {
            throw new runtime.RequiredError('recordId','Required parameter requestParameters.recordId was null or undefined when calling editRecord.');
        }

        if (requestParameters.editRecordRequest === null || requestParameters.editRecordRequest === undefined) {
            throw new runtime.RequiredError('editRecordRequest','Required parameter requestParameters.editRecordRequest was null or undefined when calling editRecord.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/record/v2/{recordId}`.replace(`{${"recordId"}}`, encodeURIComponent(String(requestParameters.recordId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: EditRecordRequestToJSON(requestParameters.editRecordRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Edit a record - license required is Full or Basic
     * Edit a record
     */
    async editRecord(requestParameters: EditRecordOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.editRecordRaw(requestParameters, initOverrides);
    }

    /**
     * Deprecated. Please use PATCH record/v2/{recordId}
     * Edit the record title
     */
    async editRecordTitleRaw(requestParameters: EditRecordTitleRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.recordId === null || requestParameters.recordId === undefined) {
            throw new runtime.RequiredError('recordId','Required parameter requestParameters.recordId was null or undefined when calling editRecordTitle.');
        }

        if (requestParameters.recordTitleChangeCommandRequest === null || requestParameters.recordTitleChangeCommandRequest === undefined) {
            throw new runtime.RequiredError('recordTitleChangeCommandRequest','Required parameter requestParameters.recordTitleChangeCommandRequest was null or undefined when calling editRecordTitle.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/record/v1/{recordId}`.replace(`{${"recordId"}}`, encodeURIComponent(String(requestParameters.recordId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: RecordTitleChangeCommandRequestToJSON(requestParameters.recordTitleChangeCommandRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deprecated. Please use PATCH record/v2/{recordId}
     * Edit the record title
     */
    async editRecordTitle(requestParameters: EditRecordTitleRequest, initOverrides?: RequestInit): Promise<void> {
        await this.editRecordTitleRaw(requestParameters, initOverrides);
    }

    /**
     * Retrieves a list of relevant users to a record.
     * Retrieves a list of users who have worked on, are working on, or could work on a record
     */
    async getRecordCollaboratorsRaw(requestParameters: GetRecordCollaboratorsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RecordCollaboratorsResponse>> {
        if (requestParameters.recordId === null || requestParameters.recordId === undefined) {
            throw new runtime.RequiredError('recordId','Required parameter requestParameters.recordId was null or undefined when calling getRecordCollaborators.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/record/v1/{recordId}/collaborators`.replace(`{${"recordId"}}`, encodeURIComponent(String(requestParameters.recordId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecordCollaboratorsResponseFromJSON(jsonValue));
    }

    /**
     * Retrieves a list of relevant users to a record.
     * Retrieves a list of users who have worked on, are working on, or could work on a record
     */
    async getRecordCollaborators(requestParameters: GetRecordCollaboratorsRequest, initOverrides?: RequestInit): Promise<RecordCollaboratorsResponse> {
        const response = await this.getRecordCollaboratorsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets child records of the given parent - license required is Full or Basic
     * GET /pcs/record-execution/record/v2/{parentRecordId}/collection?pageNumber={pageNumber}&pageSize={pageSize}&workflowId={workflow}&sortDirection={sortDirection}&sortColumn={sortColumn}
     */
    async getRecordCollectionV2Raw(requestParameters: GetRecordCollectionV2Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RecordCollectionResponseV2>> {
        if (requestParameters.parentRecordId === null || requestParameters.parentRecordId === undefined) {
            throw new runtime.RequiredError('parentRecordId','Required parameter requestParameters.parentRecordId was null or undefined when calling getRecordCollectionV2.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getRecordCollectionV2.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getRecordCollectionV2.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.workflowId !== undefined) {
            queryParameters['workflowId'] = requestParameters.workflowId;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        if (requestParameters.sortColumn !== undefined) {
            queryParameters['sortColumn'] = requestParameters.sortColumn;
        }

        if (requestParameters.recordStatuses) {
            queryParameters['recordStatuses'] = requestParameters.recordStatuses;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/record/v2/{parentRecordId}/collection`.replace(`{${"parentRecordId"}}`, encodeURIComponent(String(requestParameters.parentRecordId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecordCollectionResponseV2FromJSON(jsonValue));
    }

    /**
     * Gets child records of the given parent - license required is Full or Basic
     * GET /pcs/record-execution/record/v2/{parentRecordId}/collection?pageNumber={pageNumber}&pageSize={pageSize}&workflowId={workflow}&sortDirection={sortDirection}&sortColumn={sortColumn}
     */
    async getRecordCollectionV2(requestParameters: GetRecordCollectionV2Request, initOverrides?: RequestInit): Promise<RecordCollectionResponseV2> {
        const response = await this.getRecordCollectionV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a summary of the record by record id - License is Full or Basic
     * Retrieves a summary of the record by record id
     */
    async getRecordDetailByRecordIdRaw(requestParameters: GetRecordDetailByRecordIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RecordDetailView>> {
        if (requestParameters.recordId === null || requestParameters.recordId === undefined) {
            throw new runtime.RequiredError('recordId','Required parameter requestParameters.recordId was null or undefined when calling getRecordDetailByRecordId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/record/v1/{recordId}/detail`.replace(`{${"recordId"}}`, encodeURIComponent(String(requestParameters.recordId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecordDetailViewFromJSON(jsonValue));
    }

    /**
     * Retrieves a summary of the record by record id - License is Full or Basic
     * Retrieves a summary of the record by record id
     */
    async getRecordDetailByRecordId(requestParameters: GetRecordDetailByRecordIdRequest, initOverrides?: RequestInit): Promise<RecordDetailView> {
        const response = await this.getRecordDetailByRecordIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a summary of the record by record id - License is Full or Basic.  Supports both user-auth and s2s-auth.
     * Retrieves a summary of the record by record id
     */
    async getRecordDetailByRecordIdV2Raw(requestParameters: GetRecordDetailByRecordIdV2Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RecordDetailViewV2>> {
        if (requestParameters.recordId === null || requestParameters.recordId === undefined) {
            throw new runtime.RequiredError('recordId','Required parameter requestParameters.recordId was null or undefined when calling getRecordDetailByRecordIdV2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.pcsTenantid !== undefined && requestParameters.pcsTenantid !== null) {
            headerParameters['pcs-tenantid'] = String(requestParameters.pcsTenantid);
        }

        if (requestParameters.pcsUserid !== undefined && requestParameters.pcsUserid !== null) {
            headerParameters['pcs-userid'] = String(requestParameters.pcsUserid);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/record/v2/{recordId}/detail`.replace(`{${"recordId"}}`, encodeURIComponent(String(requestParameters.recordId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecordDetailViewV2FromJSON(jsonValue));
    }

    /**
     * Retrieves a summary of the record by record id - License is Full or Basic.  Supports both user-auth and s2s-auth.
     * Retrieves a summary of the record by record id
     */
    async getRecordDetailByRecordIdV2(requestParameters: GetRecordDetailByRecordIdV2Request, initOverrides?: RequestInit): Promise<RecordDetailViewV2> {
        const response = await this.getRecordDetailByRecordIdV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a summary of the record by record id - License is Full or Basic.  Supports both user-auth and s2s-auth.
     * Retrieves a summary of the record by record id
     */
    async getRecordDetailByRecordIdV3Raw(requestParameters: GetRecordDetailByRecordIdV3Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RecordDetailViewV3>> {
        if (requestParameters.recordId === null || requestParameters.recordId === undefined) {
            throw new runtime.RequiredError('recordId','Required parameter requestParameters.recordId was null or undefined when calling getRecordDetailByRecordIdV3.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.pcsTenantid !== undefined && requestParameters.pcsTenantid !== null) {
            headerParameters['pcs-tenantid'] = String(requestParameters.pcsTenantid);
        }

        if (requestParameters.pcsUserid !== undefined && requestParameters.pcsUserid !== null) {
            headerParameters['pcs-userid'] = String(requestParameters.pcsUserid);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/record/v3/{recordId}/detail`.replace(`{${"recordId"}}`, encodeURIComponent(String(requestParameters.recordId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecordDetailViewV3FromJSON(jsonValue));
    }

    /**
     * Retrieves a summary of the record by record id - License is Full or Basic.  Supports both user-auth and s2s-auth.
     * Retrieves a summary of the record by record id
     */
    async getRecordDetailByRecordIdV3(requestParameters: GetRecordDetailByRecordIdV3Request, initOverrides?: RequestInit): Promise<RecordDetailViewV3> {
        const response = await this.getRecordDetailByRecordIdV3Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of all participants for the given record. Required license is Full or Basic.
     * Get record participants
     */
    async getRecordParticipantsRaw(requestParameters: GetRecordParticipantsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ParticipantListResponse>> {
        if (requestParameters.recordId === null || requestParameters.recordId === undefined) {
            throw new runtime.RequiredError('recordId','Required parameter requestParameters.recordId was null or undefined when calling getRecordParticipants.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/record/v1/{recordId}/participants`.replace(`{${"recordId"}}`, encodeURIComponent(String(requestParameters.recordId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ParticipantListResponseFromJSON(jsonValue));
    }

    /**
     * Get a list of all participants for the given record. Required license is Full or Basic.
     * Get record participants
     */
    async getRecordParticipants(requestParameters: GetRecordParticipantsRequest, initOverrides?: RequestInit): Promise<ParticipantListResponse> {
        const response = await this.getRecordParticipantsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of recordIds and revisionNumbers by the seriesId or recordId. License required is Full or Basic.
     * Get recordIds and revisionNumbers by seriesId.
     */
    async getRecordSeriesRaw(requestParameters: GetRecordSeriesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SeriesRecordsResponse>> {
        const queryParameters: any = {};

        if (requestParameters.recordId !== undefined) {
            queryParameters['recordId'] = requestParameters.recordId;
        }

        if (requestParameters.seriesId !== undefined) {
            queryParameters['seriesId'] = requestParameters.seriesId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/record-series/v1`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SeriesRecordsResponseFromJSON(jsonValue));
    }

    /**
     * Get a list of recordIds and revisionNumbers by the seriesId or recordId. License required is Full or Basic.
     * Get recordIds and revisionNumbers by seriesId.
     */
    async getRecordSeries(requestParameters: GetRecordSeriesRequest = {}, initOverrides?: RequestInit): Promise<SeriesRecordsResponse> {
        const response = await this.getRecordSeriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a list of all records filtered by any criteria provided - license required is Full or Basic
     * Searches records with pagination and filtering capabilities
     */
    async getRecordsV3Raw(requestParameters: GetRecordsV3Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RecordListResponse>> {
        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getRecordsV3.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getRecordsV3.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pattern !== undefined) {
            queryParameters['pattern'] = requestParameters.pattern;
        }

        if (requestParameters.recordStatuses) {
            queryParameters['recordStatuses'] = requestParameters.recordStatuses;
        }

        if (requestParameters.fromDate !== undefined) {
            queryParameters['fromDate'] = (requestParameters.fromDate as any).toISOString();
        }

        if (requestParameters.toDate !== undefined) {
            queryParameters['toDate'] = (requestParameters.toDate as any).toISOString();
        }

        if (requestParameters.completionDateFrom !== undefined) {
            queryParameters['completionDateFrom'] = (requestParameters.completionDateFrom as any).toISOString();
        }

        if (requestParameters.completionDateTo !== undefined) {
            queryParameters['completionDateTo'] = (requestParameters.completionDateTo as any).toISOString();
        }

        if (requestParameters.dueDateFrom !== undefined) {
            queryParameters['dueDateFrom'] = (requestParameters.dueDateFrom as any).toISOString();
        }

        if (requestParameters.dueDateTo !== undefined) {
            queryParameters['dueDateTo'] = (requestParameters.dueDateTo as any).toISOString();
        }

        if (requestParameters.initiationDateFrom !== undefined) {
            queryParameters['initiationDateFrom'] = (requestParameters.initiationDateFrom as any).toISOString();
        }

        if (requestParameters.initiationDateTo !== undefined) {
            queryParameters['initiationDateTo'] = (requestParameters.initiationDateTo as any).toISOString();
        }

        if (requestParameters.lastModifiedDateFrom !== undefined) {
            queryParameters['lastModifiedDateFrom'] = (requestParameters.lastModifiedDateFrom as any).toISOString();
        }

        if (requestParameters.lastModifiedDateTo !== undefined) {
            queryParameters['lastModifiedDateTo'] = (requestParameters.lastModifiedDateTo as any).toISOString();
        }

        if (requestParameters.appIds) {
            queryParameters['appIds'] = requestParameters.appIds;
        }

        if (requestParameters.recordTypes) {
            queryParameters['recordTypes'] = requestParameters.recordTypes;
        }

        if (requestParameters.noRecordTypes) {
            queryParameters['noRecordTypes'] = requestParameters.noRecordTypes;
        }

        if (requestParameters.userIds) {
            queryParameters['userIds'] = requestParameters.userIds;
        }

        if (requestParameters.excludeRestrictedAccess !== undefined) {
            queryParameters['excludeRestrictedAccess'] = requestParameters.excludeRestrictedAccess;
        }

        if (requestParameters.dataTypes) {
            queryParameters['dataTypes'] = requestParameters.dataTypes;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/record/v3/records`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecordListResponseFromJSON(jsonValue));
    }

    /**
     * Retrieves a list of all records filtered by any criteria provided - license required is Full or Basic
     * Searches records with pagination and filtering capabilities
     */
    async getRecordsV3(requestParameters: GetRecordsV3Request, initOverrides?: RequestInit): Promise<RecordListResponse> {
        const response = await this.getRecordsV3Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of all tasks on a Record. Required license is Full or Basic.
     * Get a list of all tasks on a Record.
     */
    async getTasksOnRecordRaw(requestParameters: GetTasksOnRecordRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Task>>> {
        if (requestParameters.recordId === null || requestParameters.recordId === undefined) {
            throw new runtime.RequiredError('recordId','Required parameter requestParameters.recordId was null or undefined when calling getTasksOnRecord.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/record/v1/{recordId}/tasks`.replace(`{${"recordId"}}`, encodeURIComponent(String(requestParameters.recordId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TaskFromJSON));
    }

    /**
     * Get a list of all tasks on a Record. Required license is Full or Basic.
     * Get a list of all tasks on a Record.
     */
    async getTasksOnRecord(requestParameters: GetTasksOnRecordRequest, initOverrides?: RequestInit): Promise<Array<Task>> {
        const response = await this.getTasksOnRecordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Publish a record viewed audit event - License is Full or Basic.
     * Publish a record viewed audit event.
     */
    async publishRecordViewedEventRaw(requestParameters: PublishRecordViewedEventRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.recordId === null || requestParameters.recordId === undefined) {
            throw new runtime.RequiredError('recordId','Required parameter requestParameters.recordId was null or undefined when calling publishRecordViewedEvent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/record/v1/{recordId}/viewed`.replace(`{${"recordId"}}`, encodeURIComponent(String(requestParameters.recordId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Publish a record viewed audit event - License is Full or Basic.
     * Publish a record viewed audit event.
     */
    async publishRecordViewedEvent(requestParameters: PublishRecordViewedEventRequest, initOverrides?: RequestInit): Promise<void> {
        await this.publishRecordViewedEventRaw(requestParameters, initOverrides);
    }

    /**
     * Remove an Action Item. License required is Full or Basic.
     * Remove an Action Item.
     */
    async removeActionItemV2Raw(requestParameters: RemoveActionItemV2Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.actionItemId === null || requestParameters.actionItemId === undefined) {
            throw new runtime.RequiredError('actionItemId','Required parameter requestParameters.actionItemId was null or undefined when calling removeActionItemV2.');
        }

        if (requestParameters.actionItemDeletionRequest === null || requestParameters.actionItemDeletionRequest === undefined) {
            throw new runtime.RequiredError('actionItemDeletionRequest','Required parameter requestParameters.actionItemDeletionRequest was null or undefined when calling removeActionItemV2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/record/v2/action-item/{actionItemId}`.replace(`{${"actionItemId"}}`, encodeURIComponent(String(requestParameters.actionItemId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: ActionItemDeletionRequestToJSON(requestParameters.actionItemDeletionRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove an Action Item. License required is Full or Basic.
     * Remove an Action Item.
     */
    async removeActionItemV2(requestParameters: RemoveActionItemV2Request, initOverrides?: RequestInit): Promise<void> {
        await this.removeActionItemV2Raw(requestParameters, initOverrides);
    }

    /**
     * Remove a record from a collection - license required is Full or Basic
     * Remove a record from a collection
     */
    async removeRecordRaw(requestParameters: RemoveRecordOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.parentRecordId === null || requestParameters.parentRecordId === undefined) {
            throw new runtime.RequiredError('parentRecordId','Required parameter requestParameters.parentRecordId was null or undefined when calling removeRecord.');
        }

        if (requestParameters.removeRecordRequest === null || requestParameters.removeRecordRequest === undefined) {
            throw new runtime.RequiredError('removeRecordRequest','Required parameter requestParameters.removeRecordRequest was null or undefined when calling removeRecord.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/record/v1/{parentRecordId}/collection`.replace(`{${"parentRecordId"}}`, encodeURIComponent(String(requestParameters.parentRecordId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveRecordRequestToJSON(requestParameters.removeRecordRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove a record from a collection - license required is Full or Basic
     * Remove a record from a collection
     */
    async removeRecord(requestParameters: RemoveRecordOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.removeRecordRaw(requestParameters, initOverrides);
    }

    /**
     * License required is Full or Basic 
     * Request to reopen a record for a reason
     */
    async reopenRecordRaw(requestParameters: ReopenRecordOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.recordId === null || requestParameters.recordId === undefined) {
            throw new runtime.RequiredError('recordId','Required parameter requestParameters.recordId was null or undefined when calling reopenRecord.');
        }

        if (requestParameters.reopenRecordRequest === null || requestParameters.reopenRecordRequest === undefined) {
            throw new runtime.RequiredError('reopenRecordRequest','Required parameter requestParameters.reopenRecordRequest was null or undefined when calling reopenRecord.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/record/v1/{recordId}/action/reopen`.replace(`{${"recordId"}}`, encodeURIComponent(String(requestParameters.recordId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReopenRecordRequestToJSON(requestParameters.reopenRecordRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * License required is Full or Basic 
     * Request to reopen a record for a reason
     */
    async reopenRecord(requestParameters: ReopenRecordOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.reopenRecordRaw(requestParameters, initOverrides);
    }

    /**
     * Directly reopen a record for a reason without requiring an approval from the Event Owner to reopen the record. Only Event Owners or Sysadmins can directly reopen a record. When the selected reason to close is OTHER, comments are required. License required is Full 
     * Directly reopen a record
     */
    async reopenRecordDirectRaw(requestParameters: ReopenRecordDirectRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ChangeStatusResponse>> {
        if (requestParameters.recordId === null || requestParameters.recordId === undefined) {
            throw new runtime.RequiredError('recordId','Required parameter requestParameters.recordId was null or undefined when calling reopenRecordDirect.');
        }

        if (requestParameters.directReopenRecordRequest === null || requestParameters.directReopenRecordRequest === undefined) {
            throw new runtime.RequiredError('directReopenRecordRequest','Required parameter requestParameters.directReopenRecordRequest was null or undefined when calling reopenRecordDirect.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/record/v1/{recordId}/action/directreopen`.replace(`{${"recordId"}}`, encodeURIComponent(String(requestParameters.recordId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DirectReopenRecordRequestToJSON(requestParameters.directReopenRecordRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChangeStatusResponseFromJSON(jsonValue));
    }

    /**
     * Directly reopen a record for a reason without requiring an approval from the Event Owner to reopen the record. Only Event Owners or Sysadmins can directly reopen a record. When the selected reason to close is OTHER, comments are required. License required is Full 
     * Directly reopen a record
     */
    async reopenRecordDirect(requestParameters: ReopenRecordDirectRequest, initOverrides?: RequestInit): Promise<ChangeStatusResponse> {
        const response = await this.reopenRecordDirectRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetRecordCollectionV2SortDirectionEnum {
    Asc = 'ASC',
    Desc = 'DESC'
}
/**
    * @export
    * @enum {string}
    */
export enum GetRecordCollectionV2SortColumnEnum {
    LaunchDate = 'LAUNCH_DATE'
}
/**
    * @export
    * @enum {string}
    */
export enum GetRecordsV3DataTypesEnum {
    Validation = 'VALIDATION',
    Production = 'PRODUCTION'
}
