/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecutionPlanNode } from './ExecutionPlanNode';
import {
    ExecutionPlanNodeFromJSON,
    ExecutionPlanNodeFromJSONTyped,
    ExecutionPlanNodeToJSON,
} from './ExecutionPlanNode';
import type { Participant } from './Participant';
import {
    ParticipantFromJSON,
    ParticipantFromJSONTyped,
    ParticipantToJSON,
} from './Participant';

/**
 * 
 * @export
 * @interface NextNodesResponse
 */
export interface NextNodesResponse {
    /**
     * 
     * @type {Array<ExecutionPlanNode>}
     * @memberof NextNodesResponse
     */
    nextNodes?: Array<ExecutionPlanNode>;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof NextNodesResponse
     */
    roleUserMap?: { [key: string]: Array<string>; };
    /**
     * 
     * @type {Array<Participant>}
     * @memberof NextNodesResponse
     */
    participants?: Array<Participant>;
}

export function NextNodesResponseFromJSON(json: any): NextNodesResponse {
    return NextNodesResponseFromJSONTyped(json, false);
}

export function NextNodesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): NextNodesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nextNodes': !exists(json, 'nextNodes') ? undefined : (json['nextNodes'] as Array<ExecutionPlanNode>).map(ExecutionPlanNodeFromJSON),
        'roleUserMap': !exists(json, 'roleUserMap') ? undefined : json['roleUserMap'],
        'participants': !exists(json, 'participants') ? undefined : (json['participants'] as Array<Participant>).map(ParticipantFromJSON),
    };
}

export function NextNodesResponseToJSON(value?: NextNodesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nextNodes': value.nextNodes === undefined ? undefined : (value.nextNodes as Array<ExecutionPlanNode>).map(ExecutionPlanNodeToJSON),
        'roleUserMap': value.roleUserMap,
        'participants': value.participants === undefined ? undefined : (value.participants as Array<Participant>).map(ParticipantToJSON),
    };
}

