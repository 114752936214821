/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RecordTitleChangeCommandRequest
 */
export interface RecordTitleChangeCommandRequest {
    /**
     * 
     * @type {string}
     * @memberof RecordTitleChangeCommandRequest
     */
    title?: string;
}

export function RecordTitleChangeCommandRequestFromJSON(json: any): RecordTitleChangeCommandRequest {
    return RecordTitleChangeCommandRequestFromJSONTyped(json, false);
}

export function RecordTitleChangeCommandRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordTitleChangeCommandRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': !exists(json, 'title') ? undefined : json['title'],
    };
}

export function RecordTitleChangeCommandRequestToJSON(value?: RecordTitleChangeCommandRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
    };
}

