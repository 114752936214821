/**
 * A (potentially nested) collection of URIs, grouped by key.
 */
export type UriCollection = { [key: string]: string | UriCollection };

/**
 * A (potentially nested) collection of URI regular expressions, grouped by key.
 * NOTE: uses conditional types to properly type this.
 * https://www.typescriptlang.org/docs/handbook/2/conditional-types.html
 */
export type UriRegexCollection<T> = {
    [K in keyof T]: T[K] extends string ? RegExp : UriRegexCollection<T[K]>;
};

const baseUri = "https?://.+?/";

/**
 * Builds a mock API URI from a path string.
 * @param path The path to build a URI from.
 * @returns A regular expression that matches API urls.
 */
export const buildUriRegex = (path: string) => {
    // Build a basic URI expression that accounts for querystring
    const expression = `^${baseUri}${path.replace(/^\//, "")}(\\?.+)?$`
        // Replace /:variable/ with an appropriate regex to match any variable
        .replaceAll(/\/:\w+?(?=[/(])/g, "/[^/]+?")
        // Replace /{variable}/ with an appropriate regex to match any variable
        .replaceAll(/\/{\w+?}(?=[/(])/g, "/[^/]+?")
        // Escape all forward slashes
        .replaceAll("/", "\\/");
    return new RegExp(expression);
};

/**
 * Gets URLSearchParams from a full URL.
 * @param url The URL to retrieve search params from.
 * @returns A URLSearchParams for the URL, which may contain no entries if no parameters were provided.
 */
export function getURLSearchParams(url: string) {
    return new URL(url).searchParams;
}

/**
 * Converts a UriCollection into a UriRegexCollection.
 * @param collection The UriCollection to convert.
 * @returns A type-safe UriRegexCollection.
 */
export const buildUriRegexCollection = <T extends UriCollection>(collection: T): UriRegexCollection<T> => {
    // NOTE: use `any` as a shortcut to avoid some really nasty TypeScript generic type issues
    // that really don't provide any benefit here.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const regexCollection = {} as any;
    for (const key of Object.keys(collection)) {
        const value = collection[key];
        if (typeof value === "string") {
            regexCollection[key] = buildUriRegex(value);
        } else {
            regexCollection[key] = buildUriRegexCollection(value);
        }
    }
    return regexCollection;
};
