/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeleteParticipantsCommand
 */
export interface DeleteParticipantsCommand {
    /**
     * 
     * @type {Array<string>}
     * @memberof DeleteParticipantsCommand
     */
    participantIds?: Array<string>;
}

export function DeleteParticipantsCommandFromJSON(json: any): DeleteParticipantsCommand {
    return DeleteParticipantsCommandFromJSONTyped(json, false);
}

export function DeleteParticipantsCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeleteParticipantsCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'participantIds': !exists(json, 'participantIds') ? undefined : (json['participantIds'] as Array<string>),
    };
}

export function DeleteParticipantsCommandToJSON(value?: DeleteParticipantsCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'participantIds': value.participantIds === undefined ? undefined : (value.participantIds as Array<string>),
    };
}

