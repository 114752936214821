/* eslint-disable @typescript-eslint/member-ordering */
// Disabled for the entire file as this will be refactored out of the codebase soon.

import { BehaviorSubject, debounceTime, merge } from "rxjs";
import {
    CorrectionStatus,
    ElementData,
    EventUpdateTypeEnum,
    ExecutionPlanElement,
    ExecutionPlanElementElementTypeEnum,
    ExecutionPlanSection,
    GetTaskFormElementsRequest,
    RecordDetailView,
    RulesAppliedEvent,
    Task,
    TaskElementDataResponse,
    UpdateElementResponse,
} from "../../../generated/record-execution";
import { recordExecutionCorrectionApi, recordExecutionTaskApi } from "../../exports";
import {
    CorrectionCreatedPayload,
    CorrectionPayload,
    ElementPayload,
    ExecutionEventService,
    TaskPayload,
} from "./ExecutionEventService";

interface FormFieldValidationResult {
    data?: ElementData;
    element?: ExecutionPlanElement;
    isEmptyButRequired?: boolean;
    isValid?: boolean;
    section?: ExecutionPlanSection;
}

export interface FormValidationResults {
    isValid: boolean;
    results: FormFieldValidationResult[];
}

export type FormFieldValidationResultMap = { [key: string]: FormFieldValidationResult };

// Due to the lift and shift nature of replacing the V1 elements with V2 elements, we'll want to callout which ones should be excluded from the old validation strategy.
const validationElementExlusionList = [ExecutionPlanElementElementTypeEnum.Table];

export class TaskExecutionService {
    private _executionEventService!: ExecutionEventService;

    private _currentTaskElementDatas$ = new BehaviorSubject<ElementData[]>([]);
    private _currentTaskElements$ = new BehaviorSubject<ExecutionPlanElement[]>([]);
    private _formFieldValidationResultMap$ = new BehaviorSubject<FormFieldValidationResultMap>({});
    private _isLoading$ = new BehaviorSubject(false);
    private _markRecordDetailViewForRequery!: () => void;
    private _recordDetails$ = new BehaviorSubject<(RecordDetailView & { taskId?: string }) | null>(null);
    private _sections$ = new BehaviorSubject<ExecutionPlanSection[]>([]);
    private _task$ = new BehaviorSubject<Task | null>(null);

    /**
     * Gets an observable list of element data for elements on the current task.
     */
    public currentTaskElementDatas$ = this._currentTaskElementDatas$.asObservable();

    /**
     * Gets an observable list of elements for the current task.
     */
    public currentTaskElements$ = this._currentTaskElements$.asObservable();

    /**
     * Gets an observable map of form field validation results.
     */
    public formFieldValidationResultMap$ = this._formFieldValidationResultMap$.asObservable();

    /**
     * Returns true if the service is loading data, false otherwise.
     */
    public isLoading$ = this._isLoading$.asObservable();

    public configure(executionEventService: ExecutionEventService) {
        this._executionEventService = executionEventService;

        // Handle correction creation in a special manner
        this._executionEventService.events
            .on(EventUpdateTypeEnum.CorrectionCreated)
            .subscribe((event) => this.handleCorrectionCreated(event));

        // Handle changes to corrections as elegantly as we can
        merge(
            this._executionEventService.events.on(EventUpdateTypeEnum.CorrectionApproved),
            this._executionEventService.events.on(EventUpdateTypeEnum.CorrectionCompleted),
            this._executionEventService.events.on(EventUpdateTypeEnum.CorrectionInProgress),
            this._executionEventService.events.on(EventUpdateTypeEnum.CorrectionRejected),
        ).subscribe((event) => this.handleCorrectionUpdated(event));

        merge(
            this._executionEventService.events.on(EventUpdateTypeEnum.TaskBlocked),
            this._executionEventService.events.on(EventUpdateTypeEnum.TaskClosed),
            this._executionEventService.events.on(EventUpdateTypeEnum.TaskCompleted),
            this._executionEventService.events.on(EventUpdateTypeEnum.TaskOpened),
            this._executionEventService.events.on(EventUpdateTypeEnum.TaskPending),
            this._executionEventService.events.on(EventUpdateTypeEnum.TaskReopened),
            this._executionEventService.events.on(EventUpdateTypeEnum.TaskUnclaimed),
        ).subscribe((event) => this.handleTaskUpdated(event));

        // Originally we targeted individual element updates, but outcome of the event is to then pull all
        // form elements for the task, so this is now handling form element updates on this current record,
        // but debouncing those results so that if 10 form element updates come in at once, we only make 1 request
        // to refresh the form elements. This should make a cleaner experience until we solve the problem even better with
        // the new architecture.
        merge(
            this._executionEventService.events.on(EventUpdateTypeEnum.FormElementDataEntered),
            this._executionEventService.events.on(EventUpdateTypeEnum.FormElementUnlocked),
            this._executionEventService.events.on(EventUpdateTypeEnum.TableElementRowAdded),
            this._executionEventService.events.on(EventUpdateTypeEnum.TableElementRowDeleted),
            this._executionEventService.events.on(EventUpdateTypeEnum.TableElementRowUpdated),
        )
            .pipe(debounceTime(200))
            .subscribe((event) => void this.handleServerFormElementUpdated(event));
    }

    public async provideRecordDetails(recordDetailView: RecordDetailView | null | undefined, requery: () => void) {
        this._recordDetails$.next({ ...recordDetailView, taskId: this._task$.value?.id });
        this._markRecordDetailViewForRequery = requery;
    }

    /**
     * Gets form elements for a given task.
     * @param request The request for form elements.
     * @returns Form elements for the task.
     */
    public async getTaskFormElements(request: GetTaskFormElementsRequest): Promise<TaskElementDataResponse> {
        const response = await recordExecutionTaskApi.v1.getTaskFormElements(request);
        // Ensure elements are sorted by display order
        response.elements?.sort((a, b) => (a.displayOrder ?? 0) - (b.displayOrder ?? 0));
        this._isLoading$.next(true);

        try {
            // FIXME: if the contract marked these as required, this check is unnecessary
            if (response.elementDatas && response.elements && response.sections) {
                this.setTaskId(request.taskId);
                this._currentTaskElementDatas$.next(response.elementDatas);

                response.elements.sort((a, b) => (a.displayOrder ?? 0) - (b.displayOrder ?? 0));
                this._currentTaskElements$.next(response.elements);

                response.sections.sort((a, b) => (a.displayOrder ?? 0) - (b.displayOrder ?? 0));
                this._sections$.next(response.sections);

                const hasAnyFormFieldBeenValidated = Object.keys(this._formFieldValidationResultMap$.value).length > 0;
                // If we're in validation mode, we want to revalidate whenever
                // updates are made to the form elements, even if a new validation
                // cycle wasn't explicitly asked for
                if (hasAnyFormFieldBeenValidated) {
                    this.validateFormElementsMarkedForValidation();
                }
            }

            return response;
        } finally {
            this._isLoading$.next(false);
        }
    }

    // FIXME: not sure this is totally healthy, but I think we need a state management update anyway,
    // so we're putting this in as a temporary stop-gap.
    public async reloadFormDetails() {
        try {
            this._isLoading$.next(true);

            const task = this._task$.value;
            const taskId = task?.id;

            if (taskId) {
                await Promise.all([this.reloadTask(), this.getTaskFormElements({ taskId })]);
                this._markRecordDetailViewForRequery?.();
            }

            return Promise.reject("No task ID was provided to reload.");
        } finally {
            this._isLoading$.next(false);
        }
    }

    /**
     * Sets the task ID for the current task. The task will be automatically
     * loaded from API calls if needed.
     * @param taskId The task ID to be set, or null to deselect the current task.
     */
    private async setTaskId(taskId: string | null) {
        if (this._task$.value?.id !== taskId) {
            await this.getTask(taskId);
        }
    }

    /**
     * Validates all form elements that have been marked.
     */
    public validateFormElementsMarkedForValidation() {
        // Validate only the things that have already been validated
        const results = Object.keys(this._formFieldValidationResultMap$.value).reduce((map, key) => {
            map[key] = this.validateFormField(key, true);
            return map;
        }, {} as FormFieldValidationResultMap);
        this._formFieldValidationResultMap$.next(results);
    }

    /**
     * Validates all elements on the form.
     * @returns Validation results.
     */
    public validateForm(): FormValidationResults {
        // We need to only validate elements that are visible.
        const hiddenSectionIds = this._sections$.value.filter((section) => section.hidden).map((section) => section.id);
        const validationResults = this._currentTaskElements$.value
            .filter(
                (element) =>
                    !element.hidden && !hiddenSectionIds.includes(element.sectionId) && !element.parentElementId,
            )
            .map((element) =>
                // Don't update the state because we're going to update all of the fields
                // and then emit at the end of this function
                this.validateFormField(element.id as string, false),
            );

        // Turn the array into a map so we can search it more easily
        const validationResultMap = {} as FormFieldValidationResultMap;
        for (const validationResult of validationResults) {
            validationResultMap[validationResult.element?.id as string] = validationResult;
        }

        this._formFieldValidationResultMap$.next(validationResultMap);

        return {
            isValid: validationResults.every((result) => result.isValid === true),
            results: validationResults,
        };
    }

    /**
     * Validates a single form field.
     * @param elementId The ID of the form element to validate.
     * @param shouldSkipStateUpdate If true, bypasses validation notification updates. This is useful when validating multiple fields at once without triggering multiple updates.
     * @returns Validation results.
     */
    public validateFormField(elementId: string, shouldSkipStateUpdate?: boolean): FormFieldValidationResult {
        const element = this._currentTaskElements$.value.find((elm) => elm.id === elementId);
        const data = this._currentTaskElementDatas$.value.find((data) => data.elementId === elementId);

        const section = this._sections$.value.find((section) => section.id === element?.sectionId);
        const validationResult = { data, element, isValid: true, section } as FormFieldValidationResult;

        if (element?.required && element.elementType && !validationElementExlusionList.includes(element.elementType)) {
            const value = data?.data as unknown;
            // Check if the form field has an "empty value"
            if (value === undefined || value === null || value === "" || (Array.isArray(value) && value?.length < 1)) {
                validationResult.isEmptyButRequired = true;
                validationResult.isValid = false;
            }
        }

        if (!shouldSkipStateUpdate) {
            this._formFieldValidationResultMap$.next({
                ...this._formFieldValidationResultMap$.value,
                [elementId]: validationResult,
            });
        }

        return validationResult;
    }

    /**
     * Apply changes that occurred when a rule was applied.
     */
    private applyRuleChanges(rulesAppliedEvent: RulesAppliedEvent) {
        if (rulesAppliedEvent.elements) {
            const updatedElements = this._currentTaskElements$.value.slice();
            for (const element of rulesAppliedEvent.elements) {
                const index = updatedElements.findIndex((e) => e.id === element.id);
                if (index >= 0) {
                    updatedElements[index] = element;
                }
            }
            this._currentTaskElements$.next(updatedElements);
        }

        if (rulesAppliedEvent.elementDatas) {
            const updatedElementDatas = this._currentTaskElementDatas$.value.slice();
            for (const data of rulesAppliedEvent.elementDatas) {
                const index = updatedElementDatas.findIndex((e) => e.elementId === data.elementId);
                if (index >= 0) {
                    updatedElementDatas[index] = data;
                }
            }
            this._currentTaskElementDatas$.next(updatedElementDatas);
        }

        if (rulesAppliedEvent.sections) {
            const updatedSections = this._sections$.value.slice();
            for (const section of rulesAppliedEvent.sections) {
                const index = updatedSections.findIndex((s) => s.id === section.id);
                if (index >= 0) {
                    updatedSections[index] = section;
                }
            }
            this._sections$.next(updatedSections);
        }
    }

    /**
     * Handles updating the record detail state when a new correction is created.
     * @param event The event recorded when the correction was created.
     */
    private async handleCorrectionCreated(event: CorrectionCreatedPayload) {
        // FIXME: when RecordDetails contains the `recordId` of the current record, this
        // subscription will no longer be needed.
        const recordDetails = this._recordDetails$.getValue();
        const recordId = recordDetails?.tasks?.find((t) => t.recordId)?.recordId ?? null;
        // Only make changes if the record matches
        if (event.recordId === recordId) {
            try {
                const recordDetails = this._recordDetails$.value;
                if (recordDetails) {
                    // Get the correction that was added
                    const correction = await recordExecutionCorrectionApi.v1.getCorrection({
                        correctionId: event.correctionId,
                    });
                    // Add the correction to our list
                    const updatedCorrections = recordDetails?.corrections?.slice() ?? [];
                    updatedCorrections.push(correction);

                    // Emit the change to anyone watching
                    this._recordDetails$.next({ ...recordDetails, corrections: updatedCorrections });
                }
            } catch (err) {
                console.error("An error occurred updating correction state.");
            }
        }
    }

    /**
     * Handles updating the record detail state when a new correction is updated.
     * @param event The event recorded when the correction was updated.
     */
    private async handleCorrectionUpdated(event: CorrectionPayload) {
        try {
            const recordDetails = this._recordDetails$.value;
            if (recordDetails?.corrections) {
                const index = recordDetails.corrections.findIndex((correction) => correction.id === event.correctionId);
                if (index >= 0) {
                    // Get the correction that was updated
                    const correction = await recordExecutionCorrectionApi.v1.getCorrection({
                        correctionId: event.correctionId,
                    });
                    // Replace the correction in our list
                    const updatedCorrections = recordDetails.corrections.slice();
                    updatedCorrections.splice(index, 1, correction);

                    // Emit the change to anyone watching
                    this._recordDetails$.next({ ...recordDetails, corrections: updatedCorrections });

                    // FIXME: this shouldn't be needed - task events should come through for these.
                    // NOTE: discuss with record execution team to resolve this bug.
                    if (
                        correction.status === CorrectionStatus.Approved ||
                        correction.status === CorrectionStatus.Rejected
                    ) {
                        await this.reloadTask();
                    }
                }
            }
        } catch (err) {
            console.error("An error occurred updating correction state.");
        }
    }

    /**
     * Updates our observable list of element data
     * with changes that have been happening (live).
     * @param response The data that was updated.
     */
    public handleFormElementUpdated(response: UpdateElementResponse) {
        const elementId = response.updatedElementData.elementId as string;
        const index = this._currentTaskElementDatas$.value.findIndex(
            (elementData) => elementData.elementId === elementId,
        );
        if (index >= 0) {
            const dataListClone = this._currentTaskElementDatas$.value.slice();
            dataListClone[index] = response.updatedElementData;

            this._currentTaskElementDatas$.next(dataListClone);

            // Apply changes that occurred due to rule execution
            if (response.rulesAppliedEvent) {
                this.applyRuleChanges(response.rulesAppliedEvent);
            }

            // If the form element has already been validated, retrigger validation
            if (elementId in this._formFieldValidationResultMap$.value) {
                this.validateFormField(elementId);
            }
        }
    }

    private async handleServerFormElementUpdated(event: ElementPayload) {
        const task = this._task$.value;
        if (task?.id && task.recordId === event.recordId) {
            await this.getTaskFormElements({ taskId: task.id });
        }
    }

    /**
     * Handles updating the task state when tasks are updated.
     * @param event The event recorded when the task was updated.
     */
    private async handleTaskUpdated(event: TaskPayload) {
        const taskId = this._task$.value?.id ?? null;
        if (event.taskId === taskId) {
            // Only update state if it's our task that was updated
            await this.reloadTask();
        }
    }

    private async getTask(taskId: string | null | undefined) {
        const task = taskId ? await recordExecutionTaskApi.v1.getTask({ taskId }) : null;
        this._task$.next(task);
    }

    private async reloadTask() {
        return this.getTask(this._task$.getValue()?.id);
    }
}
