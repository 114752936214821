/* tslint:disable */
/* eslint-disable */
/**
 * Preferences OpenAPI Definition
 * All APIs for Preferences can be found here. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SettingLevel } from './SettingLevel';
import {
    SettingLevelFromJSON,
    SettingLevelFromJSONTyped,
    SettingLevelToJSON,
} from './SettingLevel';

/**
 * 
 * @export
 * @interface SettingV2
 */
export interface SettingV2 {
    /**
     * 
     * @type {string}
     * @memberof SettingV2
     */
    key: string;
    /**
     * 
     * @type {SettingLevel}
     * @memberof SettingV2
     */
    level: SettingLevel;
    /**
     * 
     * @type {boolean}
     * @memberof SettingV2
     */
    override?: boolean;
    /**
     * 
     * @type {object}
     * @memberof SettingV2
     */
    value: object;
}

export function SettingV2FromJSON(json: any): SettingV2 {
    return SettingV2FromJSONTyped(json, false);
}

export function SettingV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): SettingV2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'level': SettingLevelFromJSON(json['level']),
        'override': !exists(json, 'override') ? undefined : json['override'],
        'value': json['value'],
    };
}

export function SettingV2ToJSON(value?: SettingV2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'level': SettingLevelToJSON(value.level),
        'override': value.override,
        'value': value.value,
    };
}

