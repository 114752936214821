/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DurationUnit } from './DurationUnit';
import {
    DurationUnitFromJSON,
    DurationUnitFromJSONTyped,
    DurationUnitToJSON,
} from './DurationUnit';

/**
 * 
 * @export
 * @interface UpdateNodeRequestBody
 */
export interface UpdateNodeRequestBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateNodeRequestBody
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateNodeRequestBody
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateNodeRequestBody
     */
    requestChanges?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateNodeRequestBody
     */
    userIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateNodeRequestBody
     */
    groupIds?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof UpdateNodeRequestBody
     */
    duration?: number;
    /**
     * 
     * @type {DurationUnit}
     * @memberof UpdateNodeRequestBody
     */
    durationUnit?: DurationUnit;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateNodeRequestBody
     */
    eSigRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateNodeRequestBody
     */
    eSigNotesRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateNodeRequestBody
     */
    allowNotes?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateNodeRequestBody
     */
    allowNotApplicable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateNodeRequestBody
     */
    restrictToSingleClaimant?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateNodeRequestBody
     */
    allowCollaboratorManagement?: boolean;
}

export function UpdateNodeRequestBodyFromJSON(json: any): UpdateNodeRequestBody {
    return UpdateNodeRequestBodyFromJSONTyped(json, false);
}

export function UpdateNodeRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateNodeRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'requestChanges': !exists(json, 'requestChanges') ? undefined : json['requestChanges'],
        'userIds': !exists(json, 'userIds') ? undefined : (json['userIds'] as Array<string>),
        'groupIds': !exists(json, 'groupIds') ? undefined : (json['groupIds'] as Array<string>),
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'durationUnit': !exists(json, 'durationUnit') ? undefined : DurationUnitFromJSON(json['durationUnit']),
        'eSigRequired': !exists(json, 'eSigRequired') ? undefined : json['eSigRequired'],
        'eSigNotesRequired': !exists(json, 'eSigNotesRequired') ? undefined : json['eSigNotesRequired'],
        'allowNotes': !exists(json, 'allowNotes') ? undefined : json['allowNotes'],
        'allowNotApplicable': !exists(json, 'allowNotApplicable') ? undefined : json['allowNotApplicable'],
        'restrictToSingleClaimant': !exists(json, 'restrictToSingleClaimant') ? undefined : json['restrictToSingleClaimant'],
        'allowCollaboratorManagement': !exists(json, 'allowCollaboratorManagement') ? undefined : json['allowCollaboratorManagement'],
    };
}

export function UpdateNodeRequestBodyToJSON(value?: UpdateNodeRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'requestChanges': value.requestChanges,
        'userIds': value.userIds === undefined ? undefined : (value.userIds as Array<string>),
        'groupIds': value.groupIds === undefined ? undefined : (value.groupIds as Array<string>),
        'duration': value.duration,
        'durationUnit': DurationUnitToJSON(value.durationUnit),
        'eSigRequired': value.eSigRequired,
        'eSigNotesRequired': value.eSigNotesRequired,
        'allowNotes': value.allowNotes,
        'allowNotApplicable': value.allowNotApplicable,
        'restrictToSingleClaimant': value.restrictToSingleClaimant,
        'allowCollaboratorManagement': value.allowCollaboratorManagement,
    };
}

