import flagsmith from "flagsmith";

export const localFeatureFlagsStorageKey = "featureFlags";
export type FeatureFlagsMap<TKey extends string> = Partial<{
    [key in TKey]: boolean;
}>;

export function hasFeature(flagName: string) {
    const localFlags = getLocalFeatureFlags();
    return flagName in localFlags ? !!localFlags[flagName] : flagsmith.hasFeature(flagName);
}

export function getLocalFeatureFlags<TKey extends string>() {
    const flags = JSON.parse(localStorage.getItem(localFeatureFlagsStorageKey) || "{}");
    return flags as FeatureFlagsMap<TKey>;
}
