/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecutionPlanNode } from './ExecutionPlanNode';
import {
    ExecutionPlanNodeFromJSON,
    ExecutionPlanNodeFromJSONTyped,
    ExecutionPlanNodeToJSON,
} from './ExecutionPlanNode';

/**
 * 
 * @export
 * @interface EligibleNodesResponse
 */
export interface EligibleNodesResponse {
    /**
     * 
     * @type {Array<ExecutionPlanNode>}
     * @memberof EligibleNodesResponse
     */
    eligibleNodes?: Array<ExecutionPlanNode>;
}

export function EligibleNodesResponseFromJSON(json: any): EligibleNodesResponse {
    return EligibleNodesResponseFromJSONTyped(json, false);
}

export function EligibleNodesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EligibleNodesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'eligibleNodes': !exists(json, 'eligibleNodes') ? undefined : (json['eligibleNodes'] as Array<ExecutionPlanNode>).map(ExecutionPlanNodeFromJSON),
    };
}

export function EligibleNodesResponseToJSON(value?: EligibleNodesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'eligibleNodes': value.eligibleNodes === undefined ? undefined : (value.eligibleNodes as Array<ExecutionPlanNode>).map(ExecutionPlanNodeToJSON),
    };
}

