/* tslint:disable */
/* eslint-disable */
/**
 * Record Configuration Service OpenAPI definition
 * All APIs for Record Configuration can be found here. Including the Workflow and Form product families. Additionally, we utilize the code generated via this OpenAPI specification internally, guaranteeing that we will not diverge from the models and APIs described here. 
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DurationUnit } from './DurationUnit';
import {
    DurationUnitFromJSON,
    DurationUnitFromJSONTyped,
    DurationUnitToJSON,
} from './DurationUnit';
import type { NodeType } from './NodeType';
import {
    NodeTypeFromJSON,
    NodeTypeFromJSONTyped,
    NodeTypeToJSON,
} from './NodeType';

/**
 * 
 * @export
 * @interface AddNodeRequestBody
 */
export interface AddNodeRequestBody {
    /**
     * 
     * @type {string}
     * @memberof AddNodeRequestBody
     */
    from?: string;
    /**
     * 
     * @type {string}
     * @memberof AddNodeRequestBody
     */
    to?: string;
    /**
     * 
     * @type {NodeType}
     * @memberof AddNodeRequestBody
     */
    type: NodeType;
    /**
     * 
     * @type {string}
     * @memberof AddNodeRequestBody
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AddNodeRequestBody
     */
    description?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AddNodeRequestBody
     */
    userIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AddNodeRequestBody
     */
    groupIds?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof AddNodeRequestBody
     */
    duration?: number;
    /**
     * 
     * @type {DurationUnit}
     * @memberof AddNodeRequestBody
     */
    durationUnit?: DurationUnit;
    /**
     * 
     * @type {boolean}
     * @memberof AddNodeRequestBody
     */
    requestChanges?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AddNodeRequestBody
     */
    eSigRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AddNodeRequestBody
     */
    eSigNotesRequired?: boolean;
    /**
     * If the 'from' node is a split and the 'to' node is a join, this can be used to set the index(0-based) within the branch. If this is not set, then the node will be added to the end.
     * @type {number}
     * @memberof AddNodeRequestBody
     */
    pathIndex?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AddNodeRequestBody
     */
    allowNotes?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AddNodeRequestBody
     */
    allowNotApplicable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AddNodeRequestBody
     */
    restrictToSingleClaimant?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AddNodeRequestBody
     */
    allowCollaboratorManagement?: boolean;
}

export function AddNodeRequestBodyFromJSON(json: any): AddNodeRequestBody {
    return AddNodeRequestBodyFromJSONTyped(json, false);
}

export function AddNodeRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddNodeRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'from': !exists(json, 'from') ? undefined : json['from'],
        'to': !exists(json, 'to') ? undefined : json['to'],
        'type': NodeTypeFromJSON(json['type']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'userIds': !exists(json, 'userIds') ? undefined : (json['userIds'] as Array<string>),
        'groupIds': !exists(json, 'groupIds') ? undefined : (json['groupIds'] as Array<string>),
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'durationUnit': !exists(json, 'durationUnit') ? undefined : DurationUnitFromJSON(json['durationUnit']),
        'requestChanges': !exists(json, 'requestChanges') ? undefined : json['requestChanges'],
        'eSigRequired': !exists(json, 'eSigRequired') ? undefined : json['eSigRequired'],
        'eSigNotesRequired': !exists(json, 'eSigNotesRequired') ? undefined : json['eSigNotesRequired'],
        'pathIndex': !exists(json, 'pathIndex') ? undefined : json['pathIndex'],
        'allowNotes': !exists(json, 'allowNotes') ? undefined : json['allowNotes'],
        'allowNotApplicable': !exists(json, 'allowNotApplicable') ? undefined : json['allowNotApplicable'],
        'restrictToSingleClaimant': !exists(json, 'restrictToSingleClaimant') ? undefined : json['restrictToSingleClaimant'],
        'allowCollaboratorManagement': !exists(json, 'allowCollaboratorManagement') ? undefined : json['allowCollaboratorManagement'],
    };
}

export function AddNodeRequestBodyToJSON(value?: AddNodeRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'from': value.from,
        'to': value.to,
        'type': NodeTypeToJSON(value.type),
        'name': value.name,
        'description': value.description,
        'userIds': value.userIds === undefined ? undefined : (value.userIds as Array<string>),
        'groupIds': value.groupIds === undefined ? undefined : (value.groupIds as Array<string>),
        'duration': value.duration,
        'durationUnit': DurationUnitToJSON(value.durationUnit),
        'requestChanges': value.requestChanges,
        'eSigRequired': value.eSigRequired,
        'eSigNotesRequired': value.eSigNotesRequired,
        'pathIndex': value.pathIndex,
        'allowNotes': value.allowNotes,
        'allowNotApplicable': value.allowNotApplicable,
        'restrictToSingleClaimant': value.restrictToSingleClaimant,
        'allowCollaboratorManagement': value.allowCollaboratorManagement,
    };
}

