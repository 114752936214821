/* eslint-disable @typescript-eslint/no-unused-vars */
import { init } from "@mc/application-settings";
import { registerApplication, start } from "single-spa";
import { constructApplications, constructLayoutEngine, constructRoutes } from "single-spa-layout";
import { initI18n } from "../i18n/i18n";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const appMap: { [key: string]: () => Promise<any> } = {
    "@mc/adocs-web": () => import("@mc/adocs-web"),
    "@mc/amx-config-web": () => import("@mc/amx-config-web"),
    "@mc/amx-web": () => import("@mc/amx-web"),
    "@mc/aqem-web": () => import("@mc/aqem-web"),
    "@mc/asset-management-web": () => import("@mc/asset-management-web"),
    "@mc/atraining-web": () => import("@mc/atraining-web"),
    "@mc/auth-web": () => import("@mc/auth-web"),
    "@mc/exam-builder-web": () => import("@mc/exam-builder-web"),
    "@mc/homepage-web": () => import("@mc/homepage-web"),
    "@mc/insights-web": () => import("@mc/insights-web"),
    "@mc/logbooks-web": () => import("@mc/logbooks-web"),
    "@mc/mcadmin-web": () => import("@mc/mcadmin-web"),
    "@mc/navigation-web": () => import("@mc/navigation-web"),
    "@mc/translator-web": () => import("@mc/translator-web"),
};
const root = document.querySelector("#single-spa-layout") as Element;
const routes = constructRoutes(root);
const applications = constructApplications({
    loadApp: ({ name }) => appMap[name](),
    routes,
});

const layoutEngine = constructLayoutEngine({
    active: false,
    applications,
    routes,
});
await init(initI18n);

applications.forEach((app) => {
    registerApplication({
        ...app,
    });
});

layoutEngine.activate();
start();
