/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ActorType } from './ActorType';
import {
    ActorTypeFromJSON,
    ActorTypeFromJSONTyped,
    ActorTypeToJSON,
} from './ActorType';
import type { ParticipantType } from './ParticipantType';
import {
    ParticipantTypeFromJSON,
    ParticipantTypeFromJSONTyped,
    ParticipantTypeToJSON,
} from './ParticipantType';

/**
 * 
 * @export
 * @interface Participant
 */
export interface Participant {
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    roleId?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    ruleId?: string;
    /**
     * 
     * @type {ActorType}
     * @memberof Participant
     */
    actorType?: ActorType;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    recordId?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    nodeId?: string;
    /**
     * 
     * @type {ParticipantType}
     * @memberof Participant
     */
    participantType?: ParticipantType;
}

export function ParticipantFromJSON(json: any): Participant {
    return ParticipantFromJSONTyped(json, false);
}

export function ParticipantFromJSONTyped(json: any, ignoreDiscriminator: boolean): Participant {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'roleId': !exists(json, 'roleId') ? undefined : json['roleId'],
        'ruleId': !exists(json, 'ruleId') ? undefined : json['ruleId'],
        'actorType': !exists(json, 'actorType') ? undefined : ActorTypeFromJSON(json['actorType']),
        'recordId': !exists(json, 'recordId') ? undefined : json['recordId'],
        'nodeId': !exists(json, 'nodeId') ? undefined : json['nodeId'],
        'participantType': !exists(json, 'participantType') ? undefined : ParticipantTypeFromJSON(json['participantType']),
    };
}

export function ParticipantToJSON(value?: Participant | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userId': value.userId,
        'roleId': value.roleId,
        'ruleId': value.ruleId,
        'actorType': ActorTypeToJSON(value.actorType),
        'recordId': value.recordId,
        'nodeId': value.nodeId,
        'participantType': ParticipantTypeToJSON(value.participantType),
    };
}

