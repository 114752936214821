/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApprovalStatus,
    ApprovalStatusFromJSON,
    ApprovalStatusToJSON,
    ClaimNodeRequest,
    ClaimNodeRequestFromJSON,
    ClaimNodeRequestToJSON,
    Correction,
    CorrectionFromJSON,
    CorrectionToJSON,
    DataType,
    DataTypeFromJSON,
    DataTypeToJSON,
    DeleteNodesCommand,
    DeleteNodesCommandFromJSON,
    DeleteNodesCommandToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    Node,
    NodeFromJSON,
    NodeToJSON,
    ParticipantListResponse,
    ParticipantListResponseFromJSON,
    ParticipantListResponseToJSON,
    Record,
    RecordFromJSON,
    RecordToJSON,
    RecordV2,
    RecordV2FromJSON,
    RecordV2ToJSON,
    Task,
    TaskFromJSON,
    TaskToJSON,
    TaskListResponse,
    TaskListResponseFromJSON,
    TaskListResponseToJSON,
    UnclaimNodeRequest,
    UnclaimNodeRequestFromJSON,
    UnclaimNodeRequestToJSON,
    UsersOnNodeResponse,
    UsersOnNodeResponseFromJSON,
    UsersOnNodeResponseToJSON,
} from '../models';

export interface ClaimNodeOperationRequest {
    claimNodeRequest: ClaimNodeRequest;
}

export interface CloseNodeRequest {
    pcsTenantid: string;
    pcsAppid: string;
    nodeId: string;
}

export interface DeleteNodesByIdsRequest {
    deleteNodesCommand: DeleteNodesCommand;
}

export interface GetCorrectionsByNodeIdRequest {
    nodeId: string;
}

export interface GetNodeApprovalsRequest {
    nodeIds: Array<string>;
}

export interface GetNodeByIdRequest {
    nodeId: string;
}

export interface GetNodeParticipantsRequest {
    nodeId: string;
}

export interface GetRecordByNodeIdRequest {
    nodeId: string;
}

export interface GetRecordByNodeIdV2Request {
    nodeId: string;
}

export interface GetTasksOnNodeRequest {
    nodeId: string;
    dataType?: DataType;
}

export interface GetTasksOnNodeV2Request {
    nodeId: string;
    pageNumber: number;
    pageSize: number;
    dataType?: DataType;
}

export interface GetUsersOnNodeRequest {
    nodeId: string;
}

export interface ReopenNodeRequest {
    pcsTenantid: string;
    pcsAppid: string;
    nodeId: string;
}

export interface UnclaimNodeOperationRequest {
    unclaimNodeRequest: UnclaimNodeRequest;
}

/**
 * 
 */
export class NodeApi extends runtime.BaseAPI {

    /**
     * Claim a node that the user can work on. License required is Full or Basic.
     * Claim a node
     */
    async claimNodeRaw(requestParameters: ClaimNodeOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.claimNodeRequest === null || requestParameters.claimNodeRequest === undefined) {
            throw new runtime.RequiredError('claimNodeRequest','Required parameter requestParameters.claimNodeRequest was null or undefined when calling claimNode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/node/v1/action/claim`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClaimNodeRequestToJSON(requestParameters.claimNodeRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Claim a node that the user can work on. License required is Full or Basic.
     * Claim a node
     */
    async claimNode(requestParameters: ClaimNodeOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.claimNodeRaw(requestParameters, initOverrides);
    }

    /**
     * Close a node operation for special cases on apps. Authentication is S2S only.
     * Close a node
     */
    async closeNodeRaw(requestParameters: CloseNodeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.pcsTenantid === null || requestParameters.pcsTenantid === undefined) {
            throw new runtime.RequiredError('pcsTenantid','Required parameter requestParameters.pcsTenantid was null or undefined when calling closeNode.');
        }

        if (requestParameters.pcsAppid === null || requestParameters.pcsAppid === undefined) {
            throw new runtime.RequiredError('pcsAppid','Required parameter requestParameters.pcsAppid was null or undefined when calling closeNode.');
        }

        if (requestParameters.nodeId === null || requestParameters.nodeId === undefined) {
            throw new runtime.RequiredError('nodeId','Required parameter requestParameters.nodeId was null or undefined when calling closeNode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.pcsTenantid !== undefined && requestParameters.pcsTenantid !== null) {
            headerParameters['pcs-tenantid'] = String(requestParameters.pcsTenantid);
        }

        if (requestParameters.pcsAppid !== undefined && requestParameters.pcsAppid !== null) {
            headerParameters['pcs-appid'] = String(requestParameters.pcsAppid);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/node/v1/{nodeId}/action/close`.replace(`{${"nodeId"}}`, encodeURIComponent(String(requestParameters.nodeId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Close a node operation for special cases on apps. Authentication is S2S only.
     * Close a node
     */
    async closeNode(requestParameters: CloseNodeRequest, initOverrides?: RequestInit): Promise<void> {
        await this.closeNodeRaw(requestParameters, initOverrides);
    }

    /**
     * Supports deleting nodes by multiple ids.
     * Delete nodes by ids
     */
    async deleteNodesByIdsRaw(requestParameters: DeleteNodesByIdsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.deleteNodesCommand === null || requestParameters.deleteNodesCommand === undefined) {
            throw new runtime.RequiredError('deleteNodesCommand','Required parameter requestParameters.deleteNodesCommand was null or undefined when calling deleteNodesByIds.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/node/v1`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteNodesCommandToJSON(requestParameters.deleteNodesCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Supports deleting nodes by multiple ids.
     * Delete nodes by ids
     */
    async deleteNodesByIds(requestParameters: DeleteNodesByIdsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteNodesByIdsRaw(requestParameters, initOverrides);
    }

    /**
     * Deprecated. Please use GET correction/v1/corrections
     * Gets corrections by the toNode ID
     */
    async getCorrectionsByNodeIdRaw(requestParameters: GetCorrectionsByNodeIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Correction>>> {
        if (requestParameters.nodeId === null || requestParameters.nodeId === undefined) {
            throw new runtime.RequiredError('nodeId','Required parameter requestParameters.nodeId was null or undefined when calling getCorrectionsByNodeId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/node/v1/{nodeId}/correction`.replace(`{${"nodeId"}}`, encodeURIComponent(String(requestParameters.nodeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CorrectionFromJSON));
    }

    /**
     * Deprecated. Please use GET correction/v1/corrections
     * Gets corrections by the toNode ID
     */
    async getCorrectionsByNodeId(requestParameters: GetCorrectionsByNodeIdRequest, initOverrides?: RequestInit): Promise<Array<Correction>> {
        const response = await this.getCorrectionsByNodeIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the status of approvals for given node ids. License required is Full or Basic.
     * Get node approvals
     */
    async getNodeApprovalsRaw(requestParameters: GetNodeApprovalsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ApprovalStatus>>> {
        if (requestParameters.nodeIds === null || requestParameters.nodeIds === undefined) {
            throw new runtime.RequiredError('nodeIds','Required parameter requestParameters.nodeIds was null or undefined when calling getNodeApprovals.');
        }

        const queryParameters: any = {};

        if (requestParameters.nodeIds) {
            queryParameters['nodeIds'] = requestParameters.nodeIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/node/v1/approvals`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApprovalStatusFromJSON));
    }

    /**
     * Get the status of approvals for given node ids. License required is Full or Basic.
     * Get node approvals
     */
    async getNodeApprovals(requestParameters: GetNodeApprovalsRequest, initOverrides?: RequestInit): Promise<Array<ApprovalStatus>> {
        const response = await this.getNodeApprovalsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a Node by ID. License required is Full or Basic.
     * Get Node by ID
     */
    async getNodeByIdRaw(requestParameters: GetNodeByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Node>> {
        if (requestParameters.nodeId === null || requestParameters.nodeId === undefined) {
            throw new runtime.RequiredError('nodeId','Required parameter requestParameters.nodeId was null or undefined when calling getNodeById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/node/v1/{nodeId}`.replace(`{${"nodeId"}}`, encodeURIComponent(String(requestParameters.nodeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NodeFromJSON(jsonValue));
    }

    /**
     * Get a Node by ID. License required is Full or Basic.
     * Get Node by ID
     */
    async getNodeById(requestParameters: GetNodeByIdRequest, initOverrides?: RequestInit): Promise<Node> {
        const response = await this.getNodeByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of participants associated with the Node. Required license is Full or Basic.
     * Get participants on the Node
     */
    async getNodeParticipantsRaw(requestParameters: GetNodeParticipantsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ParticipantListResponse>> {
        if (requestParameters.nodeId === null || requestParameters.nodeId === undefined) {
            throw new runtime.RequiredError('nodeId','Required parameter requestParameters.nodeId was null or undefined when calling getNodeParticipants.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/node/v1/{nodeId}/participants`.replace(`{${"nodeId"}}`, encodeURIComponent(String(requestParameters.nodeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ParticipantListResponseFromJSON(jsonValue));
    }

    /**
     * Get a list of participants associated with the Node. Required license is Full or Basic.
     * Get participants on the Node
     */
    async getNodeParticipants(requestParameters: GetNodeParticipantsRequest, initOverrides?: RequestInit): Promise<ParticipantListResponse> {
        const response = await this.getNodeParticipantsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a record by Node ID. License required is Full or Basic.
     * Get a record by node ID
     */
    async getRecordByNodeIdRaw(requestParameters: GetRecordByNodeIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Record>> {
        if (requestParameters.nodeId === null || requestParameters.nodeId === undefined) {
            throw new runtime.RequiredError('nodeId','Required parameter requestParameters.nodeId was null or undefined when calling getRecordByNodeId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/node/v1/{nodeId}/record`.replace(`{${"nodeId"}}`, encodeURIComponent(String(requestParameters.nodeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecordFromJSON(jsonValue));
    }

    /**
     * Get a record by Node ID. License required is Full or Basic.
     * Get a record by node ID
     */
    async getRecordByNodeId(requestParameters: GetRecordByNodeIdRequest, initOverrides?: RequestInit): Promise<Record> {
        const response = await this.getRecordByNodeIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a record by Node ID. License required is Full or Basic.
     * Get a record by node ID
     */
    async getRecordByNodeIdV2Raw(requestParameters: GetRecordByNodeIdV2Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RecordV2>> {
        if (requestParameters.nodeId === null || requestParameters.nodeId === undefined) {
            throw new runtime.RequiredError('nodeId','Required parameter requestParameters.nodeId was null or undefined when calling getRecordByNodeIdV2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/node/v2/{nodeId}/record`.replace(`{${"nodeId"}}`, encodeURIComponent(String(requestParameters.nodeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecordV2FromJSON(jsonValue));
    }

    /**
     * Get a record by Node ID. License required is Full or Basic.
     * Get a record by node ID
     */
    async getRecordByNodeIdV2(requestParameters: GetRecordByNodeIdV2Request, initOverrides?: RequestInit): Promise<RecordV2> {
        const response = await this.getRecordByNodeIdV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of all tasks on a node. Required license is Full or Basic.
     * Get a list of tasks on a node.
     */
    async getTasksOnNodeRaw(requestParameters: GetTasksOnNodeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Task>>> {
        if (requestParameters.nodeId === null || requestParameters.nodeId === undefined) {
            throw new runtime.RequiredError('nodeId','Required parameter requestParameters.nodeId was null or undefined when calling getTasksOnNode.');
        }

        const queryParameters: any = {};

        if (requestParameters.dataType !== undefined) {
            queryParameters['dataType'] = requestParameters.dataType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/node/v1/{nodeId}/tasks`.replace(`{${"nodeId"}}`, encodeURIComponent(String(requestParameters.nodeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TaskFromJSON));
    }

    /**
     * Get a list of all tasks on a node. Required license is Full or Basic.
     * Get a list of tasks on a node.
     */
    async getTasksOnNode(requestParameters: GetTasksOnNodeRequest, initOverrides?: RequestInit): Promise<Array<Task>> {
        const response = await this.getTasksOnNodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a paginated list of all tasks on a node. Required license is Full or Basic.
     * Get a paginated list of tasks on a node.
     */
    async getTasksOnNodeV2Raw(requestParameters: GetTasksOnNodeV2Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TaskListResponse>> {
        if (requestParameters.nodeId === null || requestParameters.nodeId === undefined) {
            throw new runtime.RequiredError('nodeId','Required parameter requestParameters.nodeId was null or undefined when calling getTasksOnNodeV2.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getTasksOnNodeV2.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getTasksOnNodeV2.');
        }

        const queryParameters: any = {};

        if (requestParameters.dataType !== undefined) {
            queryParameters['dataType'] = requestParameters.dataType;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/node/v2/{nodeId}/tasks`.replace(`{${"nodeId"}}`, encodeURIComponent(String(requestParameters.nodeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TaskListResponseFromJSON(jsonValue));
    }

    /**
     * Get a paginated list of all tasks on a node. Required license is Full or Basic.
     * Get a paginated list of tasks on a node.
     */
    async getTasksOnNodeV2(requestParameters: GetTasksOnNodeV2Request, initOverrides?: RequestInit): Promise<TaskListResponse> {
        const response = await this.getTasksOnNodeV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deprecated. Please use node/v1/{nodeId}/participants
     * Get a list of users who can claim a node.
     */
    async getUsersOnNodeRaw(requestParameters: GetUsersOnNodeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UsersOnNodeResponse>> {
        if (requestParameters.nodeId === null || requestParameters.nodeId === undefined) {
            throw new runtime.RequiredError('nodeId','Required parameter requestParameters.nodeId was null or undefined when calling getUsersOnNode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/node/v1/{nodeId}/users`.replace(`{${"nodeId"}}`, encodeURIComponent(String(requestParameters.nodeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UsersOnNodeResponseFromJSON(jsonValue));
    }

    /**
     * Deprecated. Please use node/v1/{nodeId}/participants
     * Get a list of users who can claim a node.
     */
    async getUsersOnNode(requestParameters: GetUsersOnNodeRequest, initOverrides?: RequestInit): Promise<UsersOnNodeResponse> {
        const response = await this.getUsersOnNodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reopen a node operation for special cases on apps. Authentication is S2S only.
     * Reopen a node
     */
    async reopenNodeRaw(requestParameters: ReopenNodeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.pcsTenantid === null || requestParameters.pcsTenantid === undefined) {
            throw new runtime.RequiredError('pcsTenantid','Required parameter requestParameters.pcsTenantid was null or undefined when calling reopenNode.');
        }

        if (requestParameters.pcsAppid === null || requestParameters.pcsAppid === undefined) {
            throw new runtime.RequiredError('pcsAppid','Required parameter requestParameters.pcsAppid was null or undefined when calling reopenNode.');
        }

        if (requestParameters.nodeId === null || requestParameters.nodeId === undefined) {
            throw new runtime.RequiredError('nodeId','Required parameter requestParameters.nodeId was null or undefined when calling reopenNode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.pcsTenantid !== undefined && requestParameters.pcsTenantid !== null) {
            headerParameters['pcs-tenantid'] = String(requestParameters.pcsTenantid);
        }

        if (requestParameters.pcsAppid !== undefined && requestParameters.pcsAppid !== null) {
            headerParameters['pcs-appid'] = String(requestParameters.pcsAppid);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/node/v1/{nodeId}/action/reopen`.replace(`{${"nodeId"}}`, encodeURIComponent(String(requestParameters.nodeId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Reopen a node operation for special cases on apps. Authentication is S2S only.
     * Reopen a node
     */
    async reopenNode(requestParameters: ReopenNodeRequest, initOverrides?: RequestInit): Promise<void> {
        await this.reopenNodeRaw(requestParameters, initOverrides);
    }

    /**
     * Make a user unclaim a node. License required is Full or Basic.
     * Make a user unclaim a node.
     */
    async unclaimNodeRaw(requestParameters: UnclaimNodeOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.unclaimNodeRequest === null || requestParameters.unclaimNodeRequest === undefined) {
            throw new runtime.RequiredError('unclaimNodeRequest','Required parameter requestParameters.unclaimNodeRequest was null or undefined when calling unclaimNode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/node/v1/action/unclaim`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UnclaimNodeRequestToJSON(requestParameters.unclaimNodeRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Make a user unclaim a node. License required is Full or Basic.
     * Make a user unclaim a node.
     */
    async unclaimNode(requestParameters: UnclaimNodeOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.unclaimNodeRaw(requestParameters, initOverrides);
    }

}
