/* tslint:disable */
/* eslint-disable */
/**
 * Validation on Demand OpenAPI Definition
 * Validation on Demand negotiated endpoints
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GetValidationPlanIdByRecordIdResponse,
    GetValidationPlanIdByRecordIdResponseFromJSON,
    GetValidationPlanIdByRecordIdResponseToJSON,
    PCSBatchValidationPlanStatusesResponse,
    PCSBatchValidationPlanStatusesResponseFromJSON,
    PCSBatchValidationPlanStatusesResponseToJSON,
    PcsValidationRequest,
    PcsValidationRequestFromJSON,
    PcsValidationRequestToJSON,
    ValidationCreatedResponse,
    ValidationCreatedResponseFromJSON,
    ValidationCreatedResponseToJSON,
} from '../models';

export interface PcsValidationOnDemandPcsV1ValidationStatusesGetRequest {
    validationPlanIds: Array<string>;
}

export interface PcsValidationOnDemandPcsValidationV1PostRequest {
    pcsValidationRequest: PcsValidationRequest;
    pcsTenantid?: string;
    pcsUserid?: string;
    pcsRoles?: string;
    pcsAppid?: string;
}

export interface PcsValidationOnDemandPcsValidationV1RecordIdGetRequest {
    recordId: string;
}

/**
 * 
 */
export class PcsApi extends runtime.BaseAPI {

    /**
     * Takes a list of validation plan ids, returns a map of those ids to their associated statuses for PCS. This endpoint has a limit of 100 validationPlanIds, if more are sent, only the first 100 are looked at. Guarded by FULL/BASIC token. If a plan is not found, the id will be the key and the value will be null
     * GET pcs/validation-on-demand/pcs/validation/v1/validation/statuses?validationPlanIds={validationPlanIds}. example - /pcs/validation-on-demand/pcs/validation/statuses?validationPlanIds=ab2cb71a-b2e2-43f4-a69c-070e5edb119d,ab2cb71a-b2e2-43f4-a69c-070e5edb119e
     */
    async pcsValidationOnDemandPcsV1ValidationStatusesGetRaw(requestParameters: PcsValidationOnDemandPcsV1ValidationStatusesGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PCSBatchValidationPlanStatusesResponse>> {
        if (requestParameters.validationPlanIds === null || requestParameters.validationPlanIds === undefined) {
            throw new runtime.RequiredError('validationPlanIds','Required parameter requestParameters.validationPlanIds was null or undefined when calling pcsValidationOnDemandPcsV1ValidationStatusesGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.validationPlanIds) {
            queryParameters['validationPlanIds'] = requestParameters.validationPlanIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/validation-on-demand/pcs/v1/validation/statuses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PCSBatchValidationPlanStatusesResponseFromJSON(jsonValue));
    }

    /**
     * Takes a list of validation plan ids, returns a map of those ids to their associated statuses for PCS. This endpoint has a limit of 100 validationPlanIds, if more are sent, only the first 100 are looked at. Guarded by FULL/BASIC token. If a plan is not found, the id will be the key and the value will be null
     * GET pcs/validation-on-demand/pcs/validation/v1/validation/statuses?validationPlanIds={validationPlanIds}. example - /pcs/validation-on-demand/pcs/validation/statuses?validationPlanIds=ab2cb71a-b2e2-43f4-a69c-070e5edb119d,ab2cb71a-b2e2-43f4-a69c-070e5edb119e
     */
    async pcsValidationOnDemandPcsV1ValidationStatusesGet(requestParameters: PcsValidationOnDemandPcsV1ValidationStatusesGetRequest, initOverrides?: RequestInit): Promise<PCSBatchValidationPlanStatusesResponse> {
        const response = await this.pcsValidationOnDemandPcsV1ValidationStatusesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create and execute a validation plan on a workflow. Guarded by s2s
     * Create and execute a validation plan on a workflow. This is guarded by a s2s token
     */
    async pcsValidationOnDemandPcsValidationV1PostRaw(requestParameters: PcsValidationOnDemandPcsValidationV1PostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ValidationCreatedResponse>> {
        if (requestParameters.pcsValidationRequest === null || requestParameters.pcsValidationRequest === undefined) {
            throw new runtime.RequiredError('pcsValidationRequest','Required parameter requestParameters.pcsValidationRequest was null or undefined when calling pcsValidationOnDemandPcsValidationV1Post.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.pcsTenantid !== undefined && requestParameters.pcsTenantid !== null) {
            headerParameters['pcs-tenantid'] = String(requestParameters.pcsTenantid);
        }

        if (requestParameters.pcsUserid !== undefined && requestParameters.pcsUserid !== null) {
            headerParameters['pcs-userid'] = String(requestParameters.pcsUserid);
        }

        if (requestParameters.pcsRoles !== undefined && requestParameters.pcsRoles !== null) {
            headerParameters['pcs-roles'] = String(requestParameters.pcsRoles);
        }

        if (requestParameters.pcsAppid !== undefined && requestParameters.pcsAppid !== null) {
            headerParameters['pcs-appid'] = String(requestParameters.pcsAppid);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/validation-on-demand/pcs/validation/v1`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PcsValidationRequestToJSON(requestParameters.pcsValidationRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationCreatedResponseFromJSON(jsonValue));
    }

    /**
     * Create and execute a validation plan on a workflow. Guarded by s2s
     * Create and execute a validation plan on a workflow. This is guarded by a s2s token
     */
    async pcsValidationOnDemandPcsValidationV1Post(requestParameters: PcsValidationOnDemandPcsValidationV1PostRequest, initOverrides?: RequestInit): Promise<ValidationCreatedResponse> {
        const response = await this.pcsValidationOnDemandPcsValidationV1PostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a validation plan id by corresponding record id
     * Get a validation plan id by corresponding record id
     */
    async pcsValidationOnDemandPcsValidationV1RecordIdGetRaw(requestParameters: PcsValidationOnDemandPcsValidationV1RecordIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetValidationPlanIdByRecordIdResponse>> {
        if (requestParameters.recordId === null || requestParameters.recordId === undefined) {
            throw new runtime.RequiredError('recordId','Required parameter requestParameters.recordId was null or undefined when calling pcsValidationOnDemandPcsValidationV1RecordIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/validation-on-demand/pcs/validation/v1/{recordId}`.replace(`{${"recordId"}}`, encodeURIComponent(String(requestParameters.recordId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetValidationPlanIdByRecordIdResponseFromJSON(jsonValue));
    }

    /**
     * Get a validation plan id by corresponding record id
     * Get a validation plan id by corresponding record id
     */
    async pcsValidationOnDemandPcsValidationV1RecordIdGet(requestParameters: PcsValidationOnDemandPcsValidationV1RecordIdGetRequest, initOverrides?: RequestInit): Promise<GetValidationPlanIdByRecordIdResponse> {
        const response = await this.pcsValidationOnDemandPcsValidationV1RecordIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
