/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApproveOrRejectCorrection,
    ApproveOrRejectCorrectionFromJSON,
    ApproveOrRejectCorrectionToJSON,
    Correction,
    CorrectionFromJSON,
    CorrectionToJSON,
    CorrectionCommandResponse,
    CorrectionCommandResponseFromJSON,
    CorrectionCommandResponseToJSON,
    CorrectionCommandResponseV2,
    CorrectionCommandResponseV2FromJSON,
    CorrectionCommandResponseV2ToJSON,
    CorrectionListResponse,
    CorrectionListResponseFromJSON,
    CorrectionListResponseToJSON,
    CorrectionStatus,
    CorrectionStatusFromJSON,
    CorrectionStatusToJSON,
    DirectCorrection,
    DirectCorrectionFromJSON,
    DirectCorrectionToJSON,
    EligibleNodesResponse,
    EligibleNodesResponseFromJSON,
    EligibleNodesResponseToJSON,
    EligibleNodesResponseV2,
    EligibleNodesResponseV2FromJSON,
    EligibleNodesResponseV2ToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    NewCorrectionCommandRequest,
    NewCorrectionCommandRequestFromJSON,
    NewCorrectionCommandRequestToJSON,
    NewCorrectionCommandRequestV2,
    NewCorrectionCommandRequestV2FromJSON,
    NewCorrectionCommandRequestV2ToJSON,
    UsersOnNodeResponse,
    UsersOnNodeResponseFromJSON,
    UsersOnNodeResponseToJSON,
} from '../models';

export interface ApproveOrRejectCorrectionRequest {
    correctionId: string;
    approveOrRejectCorrection: ApproveOrRejectCorrection;
}

export interface GetCorrectionRequest {
    correctionId: string;
}

export interface GetCorrectionAssignedToUserRequest {
    userId: string;
}

export interface GetCorrectionByTaskIdRequest {
    taskId: string;
}

export interface GetCorrectionsRequest {
    pageNumber: number;
    pageSize: number;
    nodeToCorrectIds?: Array<string>;
    correctionStatuses?: Array<CorrectionStatus>;
}

export interface GetCorrectionsByTaskIdRequest {
    taskId: string;
}

export interface GetDirectCorrectionsByRecordIdRequest {
    recordId: string;
}

export interface GetEligibleNodesRequest {
    nodeId: string;
}

export interface GetEligibleNodesV2Request {
    nodeId: string;
}

export interface GetUsersToSendCorrectionRequestOnNodeRequest {
    nodeId: string;
}

export interface SubmitCorrectionRequest {
    newCorrectionCommandRequest: NewCorrectionCommandRequest;
}

export interface SubmitCorrectionV2Request {
    newCorrectionCommandRequestV2: NewCorrectionCommandRequestV2;
}

/**
 * 
 */
export class CorrectionApi extends runtime.BaseAPI {

    /**
     * If approve, reopen that node and create new tasks assigned to people on the node; otherwise close the correction. Nodes below the requested node are put back in the default state. License rqeuired is Full or Basic.
     * Approve or reject a correction
     */
    async approveOrRejectCorrectionRaw(requestParameters: ApproveOrRejectCorrectionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.correctionId === null || requestParameters.correctionId === undefined) {
            throw new runtime.RequiredError('correctionId','Required parameter requestParameters.correctionId was null or undefined when calling approveOrRejectCorrection.');
        }

        if (requestParameters.approveOrRejectCorrection === null || requestParameters.approveOrRejectCorrection === undefined) {
            throw new runtime.RequiredError('approveOrRejectCorrection','Required parameter requestParameters.approveOrRejectCorrection was null or undefined when calling approveOrRejectCorrection.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/correction/v1/{correctionId}`.replace(`{${"correctionId"}}`, encodeURIComponent(String(requestParameters.correctionId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ApproveOrRejectCorrectionToJSON(requestParameters.approveOrRejectCorrection),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * If approve, reopen that node and create new tasks assigned to people on the node; otherwise close the correction. Nodes below the requested node are put back in the default state. License rqeuired is Full or Basic.
     * Approve or reject a correction
     */
    async approveOrRejectCorrection(requestParameters: ApproveOrRejectCorrectionRequest, initOverrides?: RequestInit): Promise<void> {
        await this.approveOrRejectCorrectionRaw(requestParameters, initOverrides);
    }

    /**
     * Get a correction using correctionId. License required is Full or Basic.
     * Get a correction
     */
    async getCorrectionRaw(requestParameters: GetCorrectionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Correction>> {
        if (requestParameters.correctionId === null || requestParameters.correctionId === undefined) {
            throw new runtime.RequiredError('correctionId','Required parameter requestParameters.correctionId was null or undefined when calling getCorrection.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/correction/v1/{correctionId}`.replace(`{${"correctionId"}}`, encodeURIComponent(String(requestParameters.correctionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CorrectionFromJSON(jsonValue));
    }

    /**
     * Get a correction using correctionId. License required is Full or Basic.
     * Get a correction
     */
    async getCorrection(requestParameters: GetCorrectionRequest, initOverrides?: RequestInit): Promise<Correction> {
        const response = await this.getCorrectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deprecated. No known use.
     * Get a list of corrections assigned to a user. This isn\'t implemented.
     */
    async getCorrectionAssignedToUserRaw(requestParameters: GetCorrectionAssignedToUserRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Correction>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getCorrectionAssignedToUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/user/v1/{userId}/corrections`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CorrectionFromJSON));
    }

    /**
     * Deprecated. No known use.
     * Get a list of corrections assigned to a user. This isn\'t implemented.
     */
    async getCorrectionAssignedToUser(requestParameters: GetCorrectionAssignedToUserRequest, initOverrides?: RequestInit): Promise<Array<Correction>> {
        const response = await this.getCorrectionAssignedToUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deprecated. Please use GET task/v1/{taskId}/corrections
     * Gets a correction based on the to or the from task id
     */
    async getCorrectionByTaskIdRaw(requestParameters: GetCorrectionByTaskIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Correction>> {
        if (requestParameters.taskId === null || requestParameters.taskId === undefined) {
            throw new runtime.RequiredError('taskId','Required parameter requestParameters.taskId was null or undefined when calling getCorrectionByTaskId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/task/v1/{taskId}/correction`.replace(`{${"taskId"}}`, encodeURIComponent(String(requestParameters.taskId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CorrectionFromJSON(jsonValue));
    }

    /**
     * Deprecated. Please use GET task/v1/{taskId}/corrections
     * Gets a correction based on the to or the from task id
     */
    async getCorrectionByTaskId(requestParameters: GetCorrectionByTaskIdRequest, initOverrides?: RequestInit): Promise<Correction> {
        const response = await this.getCorrectionByTaskIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get corrections by the node IDs. License required is Full or Basic.
     * Gets corrections by the node IDs and correction statuses
     */
    async getCorrectionsRaw(requestParameters: GetCorrectionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CorrectionListResponse>> {
        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getCorrections.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getCorrections.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.nodeToCorrectIds) {
            queryParameters['nodeToCorrectIds'] = requestParameters.nodeToCorrectIds;
        }

        if (requestParameters.correctionStatuses) {
            queryParameters['correctionStatuses'] = requestParameters.correctionStatuses;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/correction/v1/corrections`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CorrectionListResponseFromJSON(jsonValue));
    }

    /**
     * Get corrections by the node IDs. License required is Full or Basic.
     * Gets corrections by the node IDs and correction statuses
     */
    async getCorrections(requestParameters: GetCorrectionsRequest, initOverrides?: RequestInit): Promise<CorrectionListResponse> {
        const response = await this.getCorrectionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get corrections using to / from task id. License required is Full or Basic.
     * Gets corrections based on the to or the from task id
     */
    async getCorrectionsByTaskIdRaw(requestParameters: GetCorrectionsByTaskIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Correction>>> {
        if (requestParameters.taskId === null || requestParameters.taskId === undefined) {
            throw new runtime.RequiredError('taskId','Required parameter requestParameters.taskId was null or undefined when calling getCorrectionsByTaskId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/task/v1/{taskId}/corrections`.replace(`{${"taskId"}}`, encodeURIComponent(String(requestParameters.taskId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CorrectionFromJSON));
    }

    /**
     * Get corrections using to / from task id. License required is Full or Basic.
     * Gets corrections based on the to or the from task id
     */
    async getCorrectionsByTaskId(requestParameters: GetCorrectionsByTaskIdRequest, initOverrides?: RequestInit): Promise<Array<Correction>> {
        const response = await this.getCorrectionsByTaskIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get direct corrections using record id. License required is Full or Basic.
     * Gets direct corrections for the specific record
     */
    async getDirectCorrectionsByRecordIdRaw(requestParameters: GetDirectCorrectionsByRecordIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<DirectCorrection>>> {
        if (requestParameters.recordId === null || requestParameters.recordId === undefined) {
            throw new runtime.RequiredError('recordId','Required parameter requestParameters.recordId was null or undefined when calling getDirectCorrectionsByRecordId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/record/v1/{recordId}/directcorrections`.replace(`{${"recordId"}}`, encodeURIComponent(String(requestParameters.recordId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DirectCorrectionFromJSON));
    }

    /**
     * Get direct corrections using record id. License required is Full or Basic.
     * Gets direct corrections for the specific record
     */
    async getDirectCorrectionsByRecordId(requestParameters: GetDirectCorrectionsByRecordIdRequest, initOverrides?: RequestInit): Promise<Array<DirectCorrection>> {
        const response = await this.getDirectCorrectionsByRecordIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deprecated. No known use since all completed steps are eligible now.
     * Gets a list of nodes in the workflow that a change can be requested to.
     */
    async getEligibleNodesRaw(requestParameters: GetEligibleNodesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EligibleNodesResponse>> {
        if (requestParameters.nodeId === null || requestParameters.nodeId === undefined) {
            throw new runtime.RequiredError('nodeId','Required parameter requestParameters.nodeId was null or undefined when calling getEligibleNodes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/node/v1/{nodeId}/corrections/eligible`.replace(`{${"nodeId"}}`, encodeURIComponent(String(requestParameters.nodeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EligibleNodesResponseFromJSON(jsonValue));
    }

    /**
     * Deprecated. No known use since all completed steps are eligible now.
     * Gets a list of nodes in the workflow that a change can be requested to.
     */
    async getEligibleNodes(requestParameters: GetEligibleNodesRequest, initOverrides?: RequestInit): Promise<EligibleNodesResponse> {
        const response = await this.getEligibleNodesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deprecated. No known use since all completed steps are eligible now.
     * Gets a list of nodes in the workflow that a change can be requested to.
     */
    async getEligibleNodesV2Raw(requestParameters: GetEligibleNodesV2Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EligibleNodesResponseV2>> {
        if (requestParameters.nodeId === null || requestParameters.nodeId === undefined) {
            throw new runtime.RequiredError('nodeId','Required parameter requestParameters.nodeId was null or undefined when calling getEligibleNodesV2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/node/v2/{nodeId}/corrections/eligible`.replace(`{${"nodeId"}}`, encodeURIComponent(String(requestParameters.nodeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EligibleNodesResponseV2FromJSON(jsonValue));
    }

    /**
     * Deprecated. No known use since all completed steps are eligible now.
     * Gets a list of nodes in the workflow that a change can be requested to.
     */
    async getEligibleNodesV2(requestParameters: GetEligibleNodesV2Request, initOverrides?: RequestInit): Promise<EligibleNodesResponseV2> {
        const response = await this.getEligibleNodesV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of users on a node who can be sent a correction request. License required is Full or Basic.
     * Get a list of users on a node who can be sent a correction request.
     */
    async getUsersToSendCorrectionRequestOnNodeRaw(requestParameters: GetUsersToSendCorrectionRequestOnNodeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UsersOnNodeResponse>> {
        if (requestParameters.nodeId === null || requestParameters.nodeId === undefined) {
            throw new runtime.RequiredError('nodeId','Required parameter requestParameters.nodeId was null or undefined when calling getUsersToSendCorrectionRequestOnNode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/node/v1/{nodeId}/users/correction`.replace(`{${"nodeId"}}`, encodeURIComponent(String(requestParameters.nodeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UsersOnNodeResponseFromJSON(jsonValue));
    }

    /**
     * Get a list of users on a node who can be sent a correction request. License required is Full or Basic.
     * Get a list of users on a node who can be sent a correction request.
     */
    async getUsersToSendCorrectionRequestOnNode(requestParameters: GetUsersToSendCorrectionRequestOnNodeRequest, initOverrides?: RequestInit): Promise<UsersOnNodeResponse> {
        const response = await this.getUsersToSendCorrectionRequestOnNodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Submits a correction. License required is Full or Basic.
     * Submits a correction.
     */
    async submitCorrectionRaw(requestParameters: SubmitCorrectionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CorrectionCommandResponse>> {
        if (requestParameters.newCorrectionCommandRequest === null || requestParameters.newCorrectionCommandRequest === undefined) {
            throw new runtime.RequiredError('newCorrectionCommandRequest','Required parameter requestParameters.newCorrectionCommandRequest was null or undefined when calling submitCorrection.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/correction/v1/action/request`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewCorrectionCommandRequestToJSON(requestParameters.newCorrectionCommandRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CorrectionCommandResponseFromJSON(jsonValue));
    }

    /**
     * Submits a correction. License required is Full or Basic.
     * Submits a correction.
     */
    async submitCorrection(requestParameters: SubmitCorrectionRequest, initOverrides?: RequestInit): Promise<CorrectionCommandResponse> {
        const response = await this.submitCorrectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Submits a correction. License required is Full or Basic.
     * Submits a correction.
     */
    async submitCorrectionV2Raw(requestParameters: SubmitCorrectionV2Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CorrectionCommandResponseV2>> {
        if (requestParameters.newCorrectionCommandRequestV2 === null || requestParameters.newCorrectionCommandRequestV2 === undefined) {
            throw new runtime.RequiredError('newCorrectionCommandRequestV2','Required parameter requestParameters.newCorrectionCommandRequestV2 was null or undefined when calling submitCorrectionV2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pcs/record-execution/correction/v2/action/request`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewCorrectionCommandRequestV2ToJSON(requestParameters.newCorrectionCommandRequestV2),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CorrectionCommandResponseV2FromJSON(jsonValue));
    }

    /**
     * Submits a correction. License required is Full or Basic.
     * Submits a correction.
     */
    async submitCorrectionV2(requestParameters: SubmitCorrectionV2Request, initOverrides?: RequestInit): Promise<CorrectionCommandResponseV2> {
        const response = await this.submitCorrectionV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

}
