/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AttachmentMediaType } from './AttachmentMediaType';
import {
    AttachmentMediaTypeFromJSON,
    AttachmentMediaTypeFromJSONTyped,
    AttachmentMediaTypeToJSON,
} from './AttachmentMediaType';

/**
 * 
 * @export
 * @interface Attachment
 */
export interface Attachment {
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    recordId?: string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    elementId?: string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    nodeId?: string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    userId?: string;
    /**
     * 
     * @type {Date}
     * @memberof Attachment
     */
    uploadTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof Attachment
     */
    fileSize?: number;
    /**
     * 
     * @type {AttachmentMediaType}
     * @memberof Attachment
     */
    mediaType?: AttachmentMediaType;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    filename?: string;
}

export function AttachmentFromJSON(json: any): Attachment {
    return AttachmentFromJSONTyped(json, false);
}

export function AttachmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Attachment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'recordId': !exists(json, 'recordId') ? undefined : json['recordId'],
        'elementId': !exists(json, 'elementId') ? undefined : json['elementId'],
        'nodeId': !exists(json, 'nodeId') ? undefined : json['nodeId'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'uploadTime': !exists(json, 'uploadTime') || json['uploadTime'] === '' ? undefined : new Date(json['uploadTime']),
        'fileSize': !exists(json, 'fileSize') ? undefined : json['fileSize'],
        'mediaType': !exists(json, 'mediaType') ? undefined : AttachmentMediaTypeFromJSON(json['mediaType']),
        'filename': !exists(json, 'filename') ? undefined : json['filename'],
    };
}

export function AttachmentToJSON(value?: Attachment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'recordId': value.recordId,
        'elementId': value.elementId,
        'nodeId': value.nodeId,
        'userId': value.userId,
        'uploadTime': value.uploadTime === undefined ? undefined : value.uploadTime.toISOString(),
        'fileSize': value.fileSize,
        'mediaType': AttachmentMediaTypeToJSON(value.mediaType),
        'filename': value.filename,
    };
}

