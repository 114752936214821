/* tslint:disable */
/* eslint-disable */
/**
 * Record Execution OpenAPI Definition
 * Record executions negotiated APIs for record aggregate
 *
 * The version of the OpenAPI document: 1.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaginationResult } from './PaginationResult';
import {
    PaginationResultFromJSON,
    PaginationResultFromJSONTyped,
    PaginationResultToJSON,
} from './PaginationResult';
import type { PartialRecordDetailViewV2 } from './PartialRecordDetailViewV2';
import {
    PartialRecordDetailViewV2FromJSON,
    PartialRecordDetailViewV2FromJSONTyped,
    PartialRecordDetailViewV2ToJSON,
} from './PartialRecordDetailViewV2';

/**
 * 
 * @export
 * @interface RecordCollectionResponseV2
 */
export interface RecordCollectionResponseV2 {
    /**
     * 
     * @type {PaginationResult}
     * @memberof RecordCollectionResponseV2
     */
    pagination?: PaginationResult;
    /**
     * 
     * @type {Array<PartialRecordDetailViewV2>}
     * @memberof RecordCollectionResponseV2
     */
    recordCollection?: Array<PartialRecordDetailViewV2>;
}

export function RecordCollectionResponseV2FromJSON(json: any): RecordCollectionResponseV2 {
    return RecordCollectionResponseV2FromJSONTyped(json, false);
}

export function RecordCollectionResponseV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordCollectionResponseV2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pagination': !exists(json, 'pagination') ? undefined : PaginationResultFromJSON(json['pagination']),
        'recordCollection': !exists(json, 'recordCollection') ? undefined : (json['recordCollection'] as Array<PartialRecordDetailViewV2>).map(PartialRecordDetailViewV2FromJSON),
    };
}

export function RecordCollectionResponseV2ToJSON(value?: RecordCollectionResponseV2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pagination': PaginationResultToJSON(value.pagination),
        'recordCollection': value.recordCollection === undefined ? undefined : (value.recordCollection as Array<PartialRecordDetailViewV2>).map(PartialRecordDetailViewV2ToJSON),
    };
}

